import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Card, Container, Button, Row, Col } from '@themesberg/react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const SignupSuccess = () => {
    const history = useHistory();
    const [personalDetails, setPersonalDetails] = useState({});
    const [companyDetails, setCompanyDetails] = useState({});
    const [selectedPackage, setSelectedPackage] = useState({});
    const [paymentDetails, setPaymentDetails] = useState({});
    const [debitOrderDetails, setDebitOrderDetails] = useState({});
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [addonsSummary, setAddonsSummary] = useState([]);
    const [isAnnual, setIsAnnual] = useState(false);

    useEffect(() => {
        const state = history.location.state;
        if (state) {
            setPersonalDetails(state.personalDetails || {});
            setCompanyDetails(state.companyDetails || {});
            setSelectedPackage(state.selectedPackage || {});
            setPaymentDetails(state.paymentDetails || {});
            setDebitOrderDetails(state.debitOrderDetails || {});
            setAgreedToTerms(state.agreedToTerms || false);
            setAddonsSummary(state.addonsSummary || []);
            setIsAnnual(state.isAnnual || false);
        }
    }, [history]);

    const transactionId = paymentDetails?.transactionId || 'Pending';
    const amountPaid = paymentDetails?.amount || 0;
    const paymentDate = paymentDetails?.date || 'N/A';
    const packageName = selectedPackage?.title || 'N/A';
    const packagePrice = isAnnual ? selectedPackage.annual_price : selectedPackage.monthly_price;

    const handleDownloadReceipt = () => {
        const doc = new jsPDF();
    
        // Text Details
        doc.text("Invoice", 14, 20);
        doc.text(`Date: ${new Date().toLocaleDateString()}`, 14, 30);
        doc.text(`Package: ${packageName}`, 14, 40);
        doc.text(`Billing Address: ${companyDetails.billing_address}`, 14, 50);
    
        // Table for Package and Add-ons
        doc.autoTable({
            startY: 60,
            head: [["Item", "Description", "Quantity", "Price (ZAR)", "Total (ZAR)"]],
            body: [
                ["Package", packageName, "1", packagePrice.toFixed(2), packagePrice.toFixed(2)],
                ...addonsSummary.map(addon => [
                    addon.name,
                    "Addon",
                    addon.quantity,
                    addon.unitPrice?.toFixed(2) || '0.00',
                    addon.totalPrice?.toFixed(2) || '0.00',
                ]),
            ],
        });
    
        // Summary of Totals
        const addonTotal = addonsSummary.reduce((total, addon) => total + addon.totalPrice, 0);
        const vatAmount = (packagePrice + addonTotal) * 0.15;
        const totalInclVAT = packagePrice + addonTotal + vatAmount;
    
        doc.autoTable({
            startY: doc.autoTable.previous.finalY + 10,
            head: [["Description", "Amount (ZAR)"]],
            body: [
                ["Subtotal (Excl. VAT)", (packagePrice + addonTotal).toFixed(2)],
                ["VAT (15%)", vatAmount.toFixed(2)],
                ["Total (Incl. VAT)", totalInclVAT.toFixed(2)],
            ],
        });
    
        doc.save(`receipt_${transactionId || 'Pending'}.pdf`);
    };

    const handleSignIn = () => {
        history.push('/login');
    };
    

    return (
        <Container fluid className="min-vh-100 d-flex flex-column bg-primary text-white py-6 justify-content-center align-items-center">
            <Row className="text-center mb-4">
                <Col>
                    <FontAwesomeIcon icon={faCheckCircle} size="4x" className="text-success" />
                    <h3 className="d-inline-block ms-3">Signup Success</h3>
                </Col>
            </Row>
            <Card className="p-4 text-black" style={{ width: '80%', maxWidth: '900px' }}>
                <Card.Body>
                    <Card.Title className="text-center mb-4">Invoice</Card.Title>
                    <Row className="mb-4">
                        <Col>
                            <h5>Personal Details</h5>
                            <ul>
                                <li>Name: {personalDetails?.firstName || 'N/A'} {personalDetails?.lastName || 'N/A'}</li>
                                <li>Email: {personalDetails?.email || 'N/A'}</li>
                                <li>Account Type: {personalDetails?.accountType || 'N/A'}</li>
                            </ul>
                        </Col>
                        <Col>
                            <h5>Company Details</h5>
                            <ul>
                                <li>Legal Name: {companyDetails?.legal_name || 'N/A'}</li>
                                <li>Trading Name: {companyDetails?.trading_name || 'N/A'}</li>
                                <li>Registration Number: {companyDetails?.registration_number || 'N/A'}</li>
                                <li>VAT Number: {companyDetails?.vat_number || 'N/A'}</li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className="mb-4 text-black">
                        <Col>
                            <h5>Selected Package</h5>
                            <p>
                                <strong>Package:</strong> {packageName}<br />
                                <strong>Price:</strong> R{packagePrice || 'N/A'}
                            </p>
                            <h6>Add-ons:</h6>
                            <ul>
                                {addonsSummary.length > 0 ? (
                                    addonsSummary.map((addon, index) => (
                                        <li key={index}>
                                            {addon.name}: {addon.quantity} x R{addon.unitPrice} = R{addon.totalPrice}
                                        </li>
                                    ))
                                ) : (
                                    <li>No add-ons selected</li>
                                )}
                            </ul>

                        </Col>
                    </Row>
                    <Row className="mb-4 text-black">
                        <Col>
                            <h5>Payment Summary</h5>
                            <p>
                                <strong>Transaction ID:</strong> {transactionId}<br />
                                <strong>Amount Paid:</strong> R{amountPaid || 'N/A'}<br />
                                <strong>Payment Date:</strong> {paymentDate}
                            </p>
                        </Col>
                    </Row>
                    <Row className="text-center text-black">
                        <Col>
                            <Button variant="primary" onClick={handleSignIn} className="me-2">
                                Sign in to account
                            </Button>
                            <Button variant="secondary" onClick={handleDownloadReceipt}>
                                Download Receipt
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default SignupSuccess;
