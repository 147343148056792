import { doPublicPostRequest } from "./requests";

export const authUser = (email, password) => {

    const payload = {
            'email': email,
            'password': password
        }

    return doPublicPostRequest(payload, 'auth/login')
}

export const registerUser = (payload) => {
    return doPublicPostRequest(payload, 'auth/register')
}