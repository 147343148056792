import React, { useState, useEffect, useRef } from "react";
import SimpleBar from 'simplebar-react';
import { Link, useHistory } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faBuilding, faChartPie, faCog, faSignOutAlt, faTimes, faFileInvoice, faPencilAlt, faChartLine, faChartBar, faFilePdf, faInbox } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Button, Accordion, Navbar, DropdownButton, Dropdown } from '@themesberg/react-bootstrap';
import { useUserRole } from '../../contexts/UserRoleContext';
import { Routes } from "../../routes";
import ProfilePicture from "../../assets/img/team/profile-picture-3.jpg";
import TourGuide from "../../utils/TourGuide";

const Sidebar = (props) => {
  const history = useHistory();

  const { userRole, setUserRole } = useUserRole();
  const [show, setShow] = useState(false);
  const [run, setRun] = useState(false);
  const [signedIn, setIsSignedIn] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  // Effect to check if the user is signed in on component mount
  useEffect(() => {
    const loggedIn = localStorage.getItem('loggedin');
    if (loggedIn === 'true') {
      setIsSignedIn(true);
    } else {
      setIsSignedIn(false);
    }
  }, []);  // Run only once on component mount

  // Sign out handler
  const handleSignOut = () => {
    // Remove the 'loggedin' key from localStorage
    localStorage.removeItem('loggedin');

    // Set signed-in state to false
    setIsSignedIn(false);

    // Refresh the current page
    setTimeout(() => {
      window.location.reload(); // Refreshes the current page
    }, 1000);
  };



  const onCollapse = () => setShow(!show);

  const CollapsableNavItem = ({ eventKey, title, icon, children = null }) => {
    return (
      <Accordion as={Nav.Item}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = ({ title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" }) => {
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <img src="../../assets/img/Forklifting.co-logo1.png" width={20} height={20} className="sidebar-icon svg-icon" /> : null}
            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  const runInitially = true;
  const isMountedRef = useRef(true);

  // Tour guide setup
  useEffect(() => {
    const startTourTimeout = setTimeout(() => {
      if (isMountedRef.current && runInitially) {
        setRun(true);
      }
    }, 500);

    return () => {
      clearTimeout(startTourTimeout);
    };
  }, [runInitially]);

  const handleTourComplete = () => {
    setRun(false);
  };

  const tourSteps = [
    {
      target: '.sidebar-inner',
      content: 'Navigate through the menu here',
    },
  ];

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.DashboardOverview.path}>
          <img src="../../../assets/img/Forklifting.co-Logo1.png" width="190px" height="20px" className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${show ? "show" : ""} sidebar d-md-block bg-blue-grey text-dark`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <img src={ProfilePicture} className="card-img-top rounded-circle border-white" />
                </div>
                <div className="d-block">
                  <h6>Hi, Jane</h6>
                  <Button as={Link} variant="secondary" size="xs" to="/sign-in" className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <TourGuide
                className="mb-2"
                steps={tourSteps}
                runInitially={false}
                stepIndex={stepIndex}
                onComplete={handleTourComplete}
              />
              <NavItem title="Dashboard" link={Routes.DashboardOverview.path} icon={faChartPie} />

              {['admin'].includes(userRole) &&
                <CollapsableNavItem eventKey="companies/" title="Companies" icon={faBuilding}>
                  <NavItem title="Companies" link={Routes.Companies.path} />
                  <NavItem title="Company Users" link={Routes.Suppliers.path} />
                </CollapsableNavItem>
              }

              {['supplier', 'buyer'].includes(userRole) &&
                <CollapsableNavItem eventKey="companies/" title="Companies" icon={faBuilding}>
                  <NavItem title="Companies" link={Routes.Companies.path} />
                  <NavItem title="User Accounts" link={Routes.UserAccounts.path} />
                </CollapsableNavItem>
              }

              {['supplier', 'admin'].includes(userRole) &&
                <CollapsableNavItem eventKey="brands/" title="Brands" icon={faUsers}>
                  <NavItem title="Brands" link={Routes.SupplyBrands.path} />
                  <NavItem title="Products" link={Routes.Listings.path} />
                  {['superuser'].includes(userRole) &&
                    <NavItem title="Services" link={Routes.Offerings.path} />
                  }
                </CollapsableNavItem>
              }

              {['superuser'].includes(userRole) &&
                <CollapsableNavItem eventKey="companies/" title="Companies" icon={faBuilding}>
                  <NavItem title="Companies" link={Routes.Companies.path} />
                  <NavItem title="Suppliers" link={Routes.Suppliers.path} />
                  <NavItem title="Buyers" link={Routes.Buyers.path} />
                </CollapsableNavItem>
              }

              <CollapsableNavItem eventKey="quotes/" title="Quotes" icon={faFileInvoice}>
                <NavItem title="Quote Requests" link={Routes.Quotes.path} />
              </CollapsableNavItem>

              {['admin'].includes(userRole) &&
                <Dropdown.Divider className="my-3 border-indigo" />
              }

              {['admin'].includes(userRole) &&
                <CollapsableNavItem eventKey="cms/" title="CMS" icon={faPencilAlt}>
                  <NavItem title="Pages" link={Routes.Pages.path} />
                  <NavItem title="Posts" link={Routes.Posts.path} />
                  <NavItem title="Categories" link={Routes.Categories.path} />
                  <NavItem title="Landing Pages" link={Routes.LandingPages.path} />
                  <NavItem title="FAQs" link={Routes.Faqs.path} />
                  <NavItem title="Media" link={Routes.Media.path} />
                </CollapsableNavItem>
              }

              {['admin', 'supplier'].includes(userRole) &&
                <NavItem title="Analytics" icon={faChartBar} link={Routes.AnalyticsDashboard.path} />
              }

              {['admin'].includes(userRole) &&
                <NavItem title="Reports" icon={faFilePdf} link={Routes.Reports.path} />
              }

              {['admin'].includes(userRole) &&
                <CollapsableNavItem eventKey="seo/" title="SEO" icon={faChartLine}>
                  <NavItem title="Robots" link={Routes.Robots.path} />
                  <NavItem title="Sitemap" link={Routes.Sitemap.path} />
                </CollapsableNavItem>
              }

              <Dropdown.Divider className="my-3 border-indigo" />

              <NavItem title="Inbox" link={Routes.Inbox.path} badgeText="1" icon={faInbox} />

              {['admin'].includes(userRole) &&
                <CollapsableNavItem eventKey="subscriptions/" title="Subscriptions" icon={faUsers}>
                  <NavItem title="Subscriptions" link={Routes.Subscriptions.path} />
                  <NavItem title="Packages" link={Routes.Packages.path} />
                  <NavItem title="User Accounts" link={Routes.UserAccounts.path} />
                  <NavItem title="Transactions" link={Routes.Transactions.path} />
                </CollapsableNavItem>
              }

              <Dropdown.Divider className="my-3 border-indigo" />

              <NavItem className="profile" title="Profile Settings" icon={faCog} link={Routes.Settings.path} />

              <Nav.Item>
                <Button variant="link" className="text-dark" onClick={handleSignOut}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Logout
                </Button>
              </Nav.Item>


              <DropdownButton id="dropdown-user-role" title={`Role: ${userRole}`} className="mt-3">
                <Dropdown.Item onClick={() => setUserRole('admin')}>Admin</Dropdown.Item>
                <Dropdown.Item onClick={() => setUserRole('supplier')}>Supplier</Dropdown.Item>
                <Dropdown.Item onClick={() => setUserRole('buyer')}>Buyer</Dropdown.Item>
                <Dropdown.Item onClick={() => setUserRole('superuser')}>Superuser</Dropdown.Item>
              </DropdownButton>
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};

export default Sidebar;