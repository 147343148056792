import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest } from "./requests";

export const createPages = (payload) => {
    return doPrivatePostRequest(payload, 'pages/create')
}

export const updatePages = (_doc_id, payload) => {
    return doPrivatePostRequest(payload, `pages/update/${_doc_id}`)
}

export const getPages = (_doc_id) => {
    return doPrivatePostRequest(`pages/get/${_doc_id}`)
}

export const getAllPages = () => {
    return doPrivateGetRequest('pages')
}

export const deletePages = (_doc_id) => {
    return doPrivateDelRequest(`pages/delete/${_doc_id}`)
}
