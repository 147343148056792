import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { makeToast } from "../../../functions/toasts";
import { authUser } from "../../../api/private/auth";
import { getAllUsers } from "../../../api/private/users";
import { Routes } from "../../../routes";
import localSessionApi from "../../../utils/constants";

const Signin = ({ onSignIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [alert, setAlert] = useState({ type: '', message: '' });
  const [signedIn, setIsSignedIn] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false); 

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') setEmail(value);
    if (name === 'password') setPassword(value);
  };

  const history = useHistory();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const loadUsersAndMatch = async (userEmail) => {
    try {
      const response = await getAllUsers();
      if (response.data.Result === 'OK') {
        console.log("Match user function ran")
        setAlert({ type: 'success', message: 'Loaded users successfully' });
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);

        // Directly use the response data to find the matched user
        const matchedUser = response.data.Records.find(user => user.email_address === userEmail);
        if (matchedUser) {
          localStorage.setItem('user_details', JSON.stringify(matchedUser));
          console.log("Matched User:", matchedUser); // Check if the user is found
        } else {
          console.warn("No matching user found");
        }
      } else {
        console.warn(response.data);
        setAlert({ type: 'error', message: 'Failed to load users' });
        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
      }
    } catch (error) {
      console.error(error);
      setAlert({ type: 'error', message: 'Failed to load users' });
      setTimeout(() => setAlert({ type: '', message: '' }), 5000);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    if (!email.trim() || !password.trim()) {
      setError('Please enter both email and password');
      return;
    }

    try {
      const response = await authUser(email, password);
      if (response.data.Result === 'SUCCESS') {
        localStorage.setItem('user_email', email);
        const access_token = response.data.Token;
        const user_data = response.data.Record;

        localSessionApi.setWithExpiry('FLC-JWT', access_token);

        if (localSessionApi.getWithExpiry('FLC-JWT')) {
          localSessionApi.setWithExpiry('FRK-AUTH-USER', user_data);
        }

        // Load users and find matched user in one function call
        await loadUsersAndMatch(email);

        // Update local storage to indicate the user is logged in
        localStorage.setItem('loggedin', 'true');
        setIsSignedIn(true);

        // Redirect to dashboard
        history.push(Routes.Presentation.path);
        onSignIn();
      } else {
        setError('Authentication failed');
      }
    } catch (error) {
      setError('Failed to log in');
      makeToast('error', 'Failed to log in');
    }
  };

  return (
    <main className="bg-primary">
      <section className="d-flex align-items-center min-vh-100 py-5 pt-lg-4 pb-lg-5">
        <Container>
        <p className="text-center">
            <Card.Link as={Link} to={Routes.Presentation.path} className="text-gray-300">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
            </Card.Link>
          </p>
          <Row className="justify-content-center form-bg-image">
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in to our platform</h3>
                </div>
                <Form className="mt-4" onSubmit={handleSubmit}>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required name="email" type="email" placeholder="example@company.com" onChange={handleChange} />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Your Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control
                        required
                        name="password"
                        type={passwordVisible ? "text" : "password"} // Dynamically change type
                        placeholder="Password"
                        onChange={handleChange}
                      />
                      <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: "pointer" }}>
                        <FontAwesomeIcon icon={passwordVisible ? faEyeSlash : faEye} />
                      </InputGroup.Text>
                    </InputGroup>
                  </Form.Group>
                  {error && <div className="py-2" style={{ textAlign: 'center', color: 'red' }}>{error}</div>}
                  <Button variant="primary" type="submit" className="w-100">
                    Sign in
                  </Button>
                </Form>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
                      {` Create account `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};

export default Signin;
