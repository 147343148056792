import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest } from "./requests";

export const createSubscriptions = (payload) => {
    return doPrivatePostRequest(payload, 'subscriptions/create');
  };
  
  export const updateSubscriptions = (payload, doc_id) => {
    return doPrivatePostRequest(payload, `subscriptions/update/${doc_id}`);
  };
  
  export const getSubscriptions = () => {
    // Retrieve the user details from localStorage
    const userDetails = localStorage.getItem("user_details");

    if (!userDetails) {
        console.error("User details not found in localStorage");
        return Promise.reject("User details not found in localStorage");
    }

    // Parse the userDetails and extract the user ID
    const userDetailsJson = JSON.parse(userDetails);
    const userId = userDetailsJson._id; // Retrieve the user ID from the parsed object

    if (!userId) {
        console.error("User ID not found in user details");
        return Promise.reject("User ID not found in user details");
    }

    // Pass the userId as a query parameter or in the request body
    return doPrivateGetRequest(`subscriptions/my-subscription/${userId}`);
};

  
  export const getAllSubscriptions = () => {
    return doPrivateGetRequest('subscriptions');
  };
  
  export const deleteSubscriptions = (doc_id) => {
    return doPrivateDelRequest(`subscriptions/delete/${doc_id}`);
  };
  