// Import axios and utilities
import axios from "axios";
import { api_token_key } from "../../utils/constants";
import localSessionApi from "../../utils/constants";

const PROXY_ENDPOINT = process.env.REACT_APP_PROXY_ENDPOINT;

// Function to get the token
function getToken() {
    const token = localSessionApi.getWithExpiry(api_token_key);
    if (!token) {
        throw new Error("No valid token found for the request");
    }
    console.log("Retrieved token:", token)
    return token;
}

// Function to handle private POST requests
export function doPrivatePostRequest(_payload, _path) {
    try {
        const token = getToken();

        const config = {
            method: 'post',
            url: `${PROXY_ENDPOINT}${_path}`,
            timeout: 40000,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: _payload
        };

        console.log(config);
        return axios(config);
    } catch (error) {
        console.error(error.message);
        return Promise.reject(error.message);
    }
}

// Function to handle private PUT requests (update)
export function doPrivatePutRequest(_payload, _path) {
    try {
        const token = getToken();

        const config = {
            method: 'put',
            url: `${PROXY_ENDPOINT}${_path}`,
            timeout: 40000,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            data: _payload
        };

        console.log(config);
        return axios(config);
    } catch (error) {
        console.error(error.message);
        return Promise.reject(error.message);
    }
}

// Function to handle private POST requests for file uploads
export function doPrivateFilePostRequest(_payload, _path) {
    try {
        const token = getToken();

        const config = {
            method: 'post',
            url: `${PROXY_ENDPOINT}${_path}`,
            timeout: 40000,
            data: _payload,
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        console.log(config);
        return axios(config);
    } catch (error) {
        console.error(error.message);
        return Promise.reject(error.message);
    }
}

// Function to handle private GET requests
export function doPrivateGetRequest(_path) {
    try {
        const token = getToken();

        const config = {
            method: 'get',
            url: `${PROXY_ENDPOINT}${_path}`,
            timeout: 40000,
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return axios(config);
    } catch (error) {
        console.error(error.message);
        return Promise.reject(error.message);
    }
}

// Function to handle private DELETE requests
export function doPrivateDelRequest(_path) {
    try {
        const token = getToken();

        const config = {
            method: 'delete',
            url: `${PROXY_ENDPOINT}${_path}`,
            timeout: 40000,
            withCredentials: true,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        };

        return axios(config);
    } catch (error) {
        console.error(error.message);
        return Promise.reject(error.message);
    }
}

// Function to handle public POST requests (no authentication)
export function doPublicPostRequest(_payload, _path) {
    const config = {
        method: 'post',
        url: `${PROXY_ENDPOINT}${_path}`,
        timeout: 40000,
        withCredentials: false,
        headers: {
            'Content-Type': 'application/json'
        },
        data: _payload
    };

    console.log(config);
    return axios(config);
}

// Function to handle public GET requests (no authentication)
export function doPublicGetRequest(_path) {
    const config = {
        method: 'get',
        url: `${PROXY_ENDPOINT}${_path}`,
        timeout: 40000,
        withCredentials: false,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    console.log(config);
    return axios(config);
}