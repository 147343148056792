import { doPublicPostRequest, doPublicGetRequest, doPublicDelRequest } from "./requests";

export const createUser = (first_name, last_name, email) => {

    const payload = {
            'first_name': first_name,
            'last_name': last_name,
            'email': email
        }

    return doPublicPostRequest(payload, 'users/create')
}

export const registerUser = (_first_name, _last_name, _email, _mobile_number, _password) => {
    const payload = {
            'first_name': _first_name,
            'last_name': _last_name,
            'email': _email,
            'mobile_number': _mobile_number,
            'password': _password
        }
    return doPublicPostRequest(payload, 'auth/register')
}
