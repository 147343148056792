import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisH, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Card, Button, Table, Dropdown, Pagination, ButtonGroup, Form } from '@themesberg/react-bootstrap';

const ITEMS_PER_PAGE = 10;

export const FaqsTables = ({ faqs, deleteFaq, handleEditFaq }) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    title: "",
    type: "",
    description: "",
    dateCreated: "",
    createdBy: ""
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const filteredFaqs = faqs.filter(faq => {
    return (
      (filters.title === "" || (faq.title ?? "").toLowerCase().includes(filters.title.toLowerCase())) &&
      (filters.type === "" || (faq.type ?? "").toLowerCase().includes(filters.type.toLowerCase())) &&
      (filters.description === "" || (faq.description ?? "").toLowerCase().includes(filters.description.toLowerCase())) &&
      (filters.dateCreated === "" || (faq.created_on ?? "").toLowerCase().includes(filters.dateCreated.toLowerCase())) &&
      (filters.createdBy === "" || (faq.created_by?.username ?? "").toLowerCase().includes(filters.createdBy.toLowerCase()))
    );
  });

  const currentTableData = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    const sortedFaqs = filteredFaqs.slice().sort((a, b) => new Date(b.created_on) - new Date(a.created_on));

    return sortedFaqs.slice(startIndex, endIndex);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageCount = Math.ceil(filteredFaqs.length / ITEMS_PER_PAGE);
  const items = [];
  for (let number = 1; number <= pageCount; number++) {
    items.push(
      <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
        {number}
      </Pagination.Item>
    );
  }

  const TableRow = (props) => {
    const { _id, title, description, type, component_content, created_on, created_by, seo_title, seo_description, canonical_link } = props;
    return (
      <tr>
        <td className="border-0">
          <Card.Link href="#" className="d-flex align-items-center" onClick={() => handleEditFaq({ _id, title, type, component_content, created_by, created_on, seo_title, seo_description, canonical_link })}>
            <div className="title-col">
              <span className="h6">{title}</span><br />
            </div>
          </Card.Link>
        </td>
        <td className="fw-bold border-0">
          {type ? type : "-"}
        </td>
        <td className="fw-bold border-0">
          {description ? description : "-"}
        </td>
        <td className="border-0">
          {created_on ? created_on : "-"}
        </td>
        <td className="fw-bold border-0">
          {created_by ? created_by.username : "-"}
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="tableNav">
              <Dropdown.Item onClick={() => handleEditFaq({ _id, title, type, component_content, created_by, created_on, seo_title, seo_description, canonical_link })}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onClick={() => deleteFaq(_id)}>
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">Title</th>
              <th className="border-0">Type</th>
              <th className="border-0">Description</th>
              <th className="border-0">Date Created</th>
              <th className="border-0">Created By</th>
              <th className="border-bottom">Action</th>
            </tr>
            <tr>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Title"
                  name="title"
                  value={filters.title}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Type"
                  name="type"
                  value={filters.type}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Description"
                  name="description"
                  value={filters.description}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Date Created"
                  name="dateCreated"
                  value={filters.dateCreated}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Created By"
                  name="createdBy"
                  value={filters.createdBy}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredFaqs.length > 0 ? (
              currentTableData().map((faq) => <TableRow key={`faq-${faq._id}`} {...faq} handleEditFaq={handleEditFaq} deleteFaq={deleteFaq} />)
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No records could be found at this time. Create your first post by clicking Add Faqs
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination className="mt-4 justify-content-center">{items}</Pagination>
      </Card.Body>
    </Card>
  );
};
