import { doPublicPostRequest } from "./requests";

export const authUser = (email, password) => {

    const payload = {
            'email': email,
            'password': password
        }

    return doPublicPostRequest(payload, 'auth/login')
}

export const registerUser = (_first_name, _last_name, _email, _mobile_number, _password) => {
    const payload = {
        'first_name': _first_name,
        'last_name': _last_name,
        'email': _email,
        'mobile_number': _mobile_number,
        'password': _password
    }
    return doPublicPostRequest(payload, 'auth/register')
}