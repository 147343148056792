// BuyerDocumentUploadTrigger.js
import React, { useEffect } from "react";
import { useDocumentUpload } from "../contexts/DocumentUploadContext";

const BuyerDocumentUploadTrigger = () => {
  const { openModal, isUploadComplete, autoOpenDisabled } = useDocumentUpload();

  useEffect(() => {
    const storedUser = localStorage.getItem("currentUser");
    if (storedUser) {
      const currentUser = JSON.parse(storedUser);
      // Auto-open only for buyers if uploads are incomplete and user hasn't manually closed it.
      if (
        currentUser.userType === "buyer" &&
        !isUploadComplete &&
        !autoOpenDisabled
      ) {
        console.log("Trigger: Opening modal automatically");
        openModal();
      }
    }
  }, [openModal, isUploadComplete, autoOpenDisabled]);

  return null;
};

export default BuyerDocumentUploadTrigger;
