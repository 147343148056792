
import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faArrowDown, faArrowUp, faEdit, faEllipsisH, faExternalLinkAlt, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Nav, Card, Image, Button, Table, Dropdown, ProgressBar, Pagination, ButtonGroup } from '@themesberg/react-bootstrap';

const ValueChange = ({ value, suffix }) => {
  const valueIcon = value < 0 ? faAngleDown : faAngleUp;
  const valueTxtColor = value < 0 ? "text-danger" : "text-success";

  return (
    value ? <span className={valueTxtColor}>
      <FontAwesomeIcon icon={valueIcon} />
      <span className="fw-bold ms-1">
        {Math.abs(value)}{suffix}
      </span>
    </span> : "--"
  );
};

const ITEMS_PER_PAGE = 10;

export const BuyersTables = ({ buyers, handleEditBuyer, deleteBuyer, users, companies }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return buyers.slice(startIndex, endIndex);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageCount = Math.ceil(buyers.length / ITEMS_PER_PAGE);
  const items = [];
  for (let number = 1; number <= pageCount; number++) {
    items.push(
      <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
        {number}
      </Pagination.Item>
    );
  }

  const TableRow = ({ _id, linked_user, linked_companies, created_on }) => {
    // Find the linked user by _id, ensure linked_user is defined
    const linkedUser = linked_user ? users.find(user => user._id === linked_user) : null;
    const linkedUserEmail = linkedUser ? linkedUser.email_address : "-";

    // Ensure linked_companies is defined before trying to find the linked company
    const linkedCompany = linked_companies ? companies.find(company => company._id === linked_companies) : null;
    const linkedCompanyName = linkedCompany ? linkedCompany.legal_name : "-";

    return (
      <tr>
        <td className="border-0">
          <Card.Link href="#" className="d-flex align-items-center" onClick={() => handleEditBuyer({_id, linked_user, linked_companies, created_on })}>
            <div><span className="h6">{linkedCompanyName}</span></div>
          </Card.Link>
        </td>
        <td className="fw-bold border-0">
          {linkedUserEmail}
        </td>
        <td>{linkedCompanyName}</td>
        <td className="border-0">
          {created_on ? created_on : "-"}
        </td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="tableNav">
              <Dropdown.Item onClick={() => handleEditBuyer({_id, linked_user, linked_companies, created_on })}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onClick={() => deleteBuyer(_id)}>
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
    <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
            <thead className="thead-light">
                <tr>
                    <th className="border-0">Linked Company</th>
                    <th className="border-0">Email</th>
                    <th className="border-0">Linked User</th>
                    <th className="border-0">Date Created</th>
                    <th className="border-bottom">Action</th>
                </tr>
            </thead>
            <tbody>
                {buyers.length > 0 ? (
                    currentTableData().map(buyer => <TableRow key={`buyer-${buyer._id}`} {...buyer} />)
                ) : (
                    <tr>
                        <td colSpan="6" className="text-center">
                            No records could be found at this time. Create your first supplier by clicking Add Buyer
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
        <Pagination className="mt-4 justify-content-center">{items}</Pagination>
    </Card.Body>
</Card>
  );
};