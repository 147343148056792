import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useDocumentUpload } from "../../contexts/DocumentUploadContext";
import { FaTimes, FaExclamationTriangle } from "react-icons/fa"; // Danger icon for minimized view

const PendingUploadAlert = () => {
  // Always call hooks at the top
  const location = useLocation();
  const { openModal, isUploadComplete, setUploadedDocs } = useDocumentUpload();
  const [isMinimized, setIsMinimized] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(true);

  // Only show alert for logged-in users
  const isLoggedIn = localStorage.getItem("loggedin") === "true";
  if (!isLoggedIn) {
    return null;
  }

  // Only show on pages with the slug /admin/* or /dashboard
  const pathname = location.pathname;
  const isAdminOrDashboard = pathname.startsWith("/admin") || pathname === "/dashboard";
  if (!isAdminOrDashboard) {
    return null;
  }

  // SUCCESS ALERT: When uploads are complete (verification successful)
  if (isUploadComplete) {
    if (!showSuccessAlert) return null; // Once closed, don't show any minimized element
    return (
      <div
        className="alert alert-success fixed-top text-center"
        role="alert"
        style={{ zIndex: 1050, padding: "10px 20px" }}
      >
        <div>✅ Successfully submitted verification documents</div>
        <p>
          Navigate around the system, publish your profile while you wait.
          Once you're verified, your profile will be available to the world.
        </p>
        <div>
          {/* Close button now dismisses the success alert permanently */}
          <button
            onClick={() => setShowSuccessAlert(false)}
            className="btn btn-secondary"
            style={{ marginRight: "15px" }}
          >
            Close
          </button>
          {/* Re-submit button to clear current uploads and re-open the modal */}
          <button
            onClick={() => {
              setUploadedDocs({}); // Clear current uploads so isUploadComplete becomes false
              openModal();         // Open the modal for fresh uploads
              setShowSuccessAlert(true); // Ensure the success alert is shown next time
            }}
            className="btn btn-primary"
          >
            Re-submit
          </button>
        </div>
      </div>
    );
  }

  // PENDING ALERT (verification not successful)
  // If minimized, show the small icon.
  if (isMinimized) {
    return (
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: 1050,
          cursor: "pointer",
          backgroundColor: "#dc3545", // Bootstrap danger red
          borderRadius: "50%",
          padding: "15px",
          boxShadow: "0 2px 5px rgba(0,0,0,0.3)"
        }}
        onClick={() => setIsMinimized(false)}
        title="Expand pending uploads"
      >
        <FaExclamationTriangle size={30} color="#fff" />
      </div>
    );
  }

  // PENDING ALERT: When uploads are not complete and not minimized.
  return (
    <div
      className="alert alert-warning fixed-top text-center"
      role="alert"
      style={{
        zIndex: 1050,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 20px"
      }}
    >
      <div>
        📌 Pending document uploads. Please upload your docs to verify.
      </div>
      <div>
        <button
          onClick={openModal}
          className="btn btn-primary"
          style={{ marginRight: "15px" }}  // Adds space between buttons
        >
          Verify
        </button>
        <button
          onClick={() => setIsMinimized(true)}
          className="btn btn-secondary"
          title="Minimize"
        >
          <FaTimes />
        </button>
      </div>
    </div>
  );
};

export default PendingUploadAlert;
