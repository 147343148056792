import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";
import Preloader from "../components/Preloader";
import Sidebar from "../components/backendNav/Sidebar";
import Navbar from "../components/backendNav/Navbar";
import Footer from "../components/backendNav/Footer";


// public pages
import Presentation from "./Presentation";
import StartYourSearch from "./public/StartYourSearch";
import Brands from "./public/brands/Brands";
import BrandsView from "./public/brands/BrandsView";
import FrequentlyAsked from "./public/FrequentlyAsked";
import ProductServices from "./public/productsServices/ProductServices";
import ProductList from "./public/productsServices/products/productsList";
import ProductsView from "./public/productsServices/products/productsView";
import AllListings from "./public/listings/AllListings";
import Support from "./public/support/Support";
import CompanySearch from "./public/companies/Companies";
import CompanyView from "./public/companies/CompanyView";
import About from "./public/About";
import Insights from "./public/blogs/Insights";
import BlogRead from "./public/blogs/BlogRead";
import Contact from "./public/Contact";
import Careers from "./public/Careers";
import ForSuppliers from "./public/ForSuppliers";

//Wizard pages
import Wizard from "./wizard/wizard";
import Quote from "./wizard/productQuote";

// admin pages
import DashboardOverview from "./admin/dashboard/DashboardOverview";
import AnalyticsDashboard from "./admin/analytics/AnalyticsDashboard";
import Transactions from "./admin/subscriptions/Transactions";
import Settings from "./admin/user/Settings";
import Reports from './admin/reports/Reports';
import Signin from "./admin/auth/Signin";
import Signup from "./admin/auth/Signup";
import SignupCancel from './admin/auth/SignupCancel';
import SignupSuccess from './admin/auth/SignupSuccess';
import Signout from "./admin/auth/Signout";
import ForgotPassword from "./admin/auth/ForgotPassword";
import ResetPassword from "./admin/auth/ResetPassword";
import NotFoundPage from "./NotFound";
import ServerError from "./ServerError";
import LandingPages from "./admin/cms/LandingPages";
import Categories from "./admin/cms/Categories";

// admin
import Listings from "./admin/brands/Listings";
import SupplyBrands from "./admin/brands/Brands";
import Offerings from "./admin/brands/Offerings";
import Requests from "./admin/quotes/Requests";
import Quotes from "./admin/quotes/Quotes";
import Suppliers from "./admin/companies/Suppliers";
import Packages from "./admin/subscriptions/Packages";
import Buyers from "./admin/companies/Buyers";
import Companies from "./admin/companies/Companies";
import UserAccounts from "./admin/subscriptions/UserAccounts";
import Subscriptions from "./admin/subscriptions/Subscriptions";
import Pages from "./admin/cms/Pages";
import Faqs from "./admin/cms/Faqs";
import Posts from "./admin/cms/Posts";
import Media from "./admin/cms/Media";
import Inbox from "./admin/chat/Inbox";
import Robots from "./admin/seo/Robots";
import Sitemap from "./admin/seo/Sitemap";

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => (
      <div className="page-no-sidebar">
        <Preloader show={loaded ? false : true} />
        <Component {...props} />
      </div>
    )}
    />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const [signedIn, setIsSignedIn] = useState(false);
  const [loading, setLoading] = useState(true); // Add a loading state to avoid premature redirection

  useEffect(() => {
    const loggedIn = localStorage.getItem('loggedin');
    setIsSignedIn(loggedIn === 'true');
    setLoading(false); // Mark the check as complete

    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true;
  };

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  };

  // Show loader while checking login status
  if (loading) {
    return <Preloader show={true} />;
  }

  return (
    <Route {...rest} render={props => (
      signedIn ? (
        <div className="page-with-sidebar">
          <Preloader show={loaded ? false : true} />
          <Sidebar />
          <main className="content">
            <Navbar />
            <Component {...props} />
            <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
          </main>
        </div>
      ) : (
        <Redirect to={Routes.Signin.path} />
      )
    )}
    />
  );
};

export default () => (
  <Switch>
    {/* public pages */}
    <RouteWithLoader exact path={Routes.Presentation.path} component={Presentation} />
    <RouteWithLoader exact path={Routes.StartYourSearch.path} component={StartYourSearch} />
    <RouteWithLoader exact path={Routes.ProductServices.path} component={ProductServices} />
    <RouteWithLoader exact path={Routes.ProductList.path} component={ProductList} />
    <RouteWithLoader exact path={Routes.ProductsView.path} component={ProductsView} />
    <RouteWithLoader exact path={Routes.Brands.path} component={Brands} />
    <RouteWithLoader exact path={Routes.BrandsView.path} component={BrandsView} />
    <RouteWithLoader exact path={Routes.CompanySearch.path} component={CompanySearch} />
    <RouteWithLoader exact path={Routes.CompanyView.path} component={CompanyView} />
    <RouteWithLoader exact path={Routes.Support.path} component={Support} />
    <RouteWithLoader exact path={Routes.AllListings.path} component={AllListings} />
    <RouteWithLoader exact path={Routes.FrequentlyAsked.path} component={FrequentlyAsked} />
    <RouteWithLoader exact path={Routes.About.path} component={About} />
    <RouteWithLoader exact path={Routes.Careers.path} component={Careers} />
    <RouteWithLoader exact path={Routes.Insights.path} component={Insights} />
    <RouteWithLoader exact path={Routes.BlogRead.path} component={BlogRead} />
    <RouteWithLoader exact path={Routes.ForSuppliers.path} component={ForSuppliers} />
    <RouteWithLoader exact path={Routes.Contact.path} component={Contact} />

    {/* Wizard */}
    <RouteWithLoader exact path={Routes.Wizard.path} component={Wizard} />
    <RouteWithLoader exact path={Routes.Quote.path} component={Quote} />

    {/* admin pages */}
    <RouteWithSidebar exact path={Routes.Listings.path} component={Listings} />
    <RouteWithSidebar exact path={Routes.AnalyticsDashboard.path} component={AnalyticsDashboard} />
    <RouteWithSidebar exact path={Routes.Offerings.path} component={Offerings} />
    <RouteWithSidebar exact path={Routes.SupplyBrands.path} component={SupplyBrands} />
    <RouteWithSidebar exact path={Routes.Requests.path} component={Requests} />
    <RouteWithSidebar exact path={Routes.Quotes.path} component={Quotes} />
    <RouteWithSidebar exact path={Routes.Reports.path} component={Reports} />
    <RouteWithSidebar exact path={Routes.Companies.path} component={Companies} />
    <RouteWithSidebar exact path={Routes.Suppliers.path} component={Suppliers} />
    <RouteWithSidebar exact path={Routes.Buyers.path} component={Buyers} />
    <RouteWithSidebar exact path={Routes.Inbox.path} component={Inbox} />
    <RouteWithSidebar exact path={Routes.Subscriptions.path} component={Subscriptions} />
    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
    <RouteWithSidebar exact path={Routes.Transactions.path} component={Transactions} />
    <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
    <RouteWithSidebar exact path={Routes.UserAccounts.path} component={UserAccounts} />
    <RouteWithSidebar exact path={Routes.Packages.path} component={Packages} />
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
    <RouteWithLoader exact path={Routes.SignupCancel.path} component={SignupCancel} />
    <RouteWithLoader exact path={Routes.SignupSuccess.path} component={SignupSuccess} />
    <RouteWithLoader exact path={Routes.Signout.path} component={Signout} />
    <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
    <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} />
    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithSidebar exact path={Routes.ServerError.path} component={ServerError} />
    <RouteWithSidebar exact path={Routes.LandingPages.path} component={LandingPages} />
    <RouteWithSidebar exact path={Routes.Categories.path} component={Categories} />
    <RouteWithSidebar exact path={Routes.Pages.path} component={Pages} />
    <RouteWithSidebar exact path={Routes.Posts.path} component={Posts} />
    <RouteWithSidebar exact path={Routes.Faqs.path} component={Faqs} />
    <RouteWithSidebar exact path={Routes.Media.path} component={Media} />
    <RouteWithSidebar exact path={Routes.Robots.path} component={Robots} />
    <RouteWithSidebar exact path={Routes.Sitemap.path} component={Sitemap} />

    <Redirect to={Routes.NotFound.path} />
  </Switch>
);