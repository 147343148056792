import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest } from "./requests";

export const createBuyers = (payload) => {
    return doPrivatePostRequest(payload, 'buyers/create')
}

export const updateBuyers = () => {
    const payload = {}
    return doPrivatePostRequest(payload, 'buyers/update')
}

export const getBuyers = (_doc_id) => {
    return doPrivatePostRequest(`buyers/get/${_doc_id}`)
}

export const getAllBuyers = () => {
    return doPrivateGetRequest('buyers')
}

export const deleteBuyers = (doc_id) => {
    return doPrivateDelRequest(`buyers/delete/${doc_id}`)
}
