import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisH, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Card, Table, Dropdown, Pagination, ButtonGroup, Button } from '@themesberg/react-bootstrap';

export const SuppliersTables = ({ suppliers, deleteSupplier, handleEditSupplier }) => {
  
  const ITEMS_PER_PAGE = 10;
  const suppliersArray = Object.values(suppliers);

  const TableRow = ({ _id, linked_company, linked_user, created_by }) => {
    const companyName = linked_company ? linked_company.name : 'N/A';
    const companyUsers = linked_user ? linked_user : [];

    return (
        <tr>
            <td onClick={() => handleEditSupplier({ _id, linked_company, linked_user, created_by  })}>{companyName}</td>
            <td></td>
            <td>
                {companyUsers.length > 0 ? (
                    companyUsers.map((user, index) => (
                        <div key={index}>{user.name}</div>
                    ))
                ) : (
                    'N/A'
                )}
            </td>
            <td>{created_by.username || 'N/A'}</td>
            <td>
                <Dropdown className="tableNav" as={ButtonGroup}>
                    <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
                        <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="tableNav">
                        <Dropdown.Item onClick={() => handleEditSupplier({ _id, linked_company, linked_user, created_by  })}>
                            <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
                        </Dropdown.Item>
                        <Dropdown.Item className="text-danger" onClick={() => deleteSupplier(_id)}>
                            <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </td>
        </tr>
    );
  };

  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return suppliersArray.slice(startIndex, endIndex);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageCount = Math.ceil(suppliersArray.length / ITEMS_PER_PAGE);
  const items = [];
  for (let number = 1; number <= pageCount; number++) {
    items.push(
      <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
        {number}
      </Pagination.Item>
    );
  }

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th>Linked Company</th>
              <th>Assigned Brands</th>
              <th>Linked Users</th>
              <th>Created By</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {suppliersArray.length > 0 ? (
              currentTableData().map(supplier => (
                <TableRow key={`supplier-${supplier['_id']}`} {...supplier} />
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  No records could be found at this time. Create your first supplier by clicking Add Supplier.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination className="mt-4 justify-content-center">{items}</Pagination>
      </Card.Body>
    </Card>
  );
};
