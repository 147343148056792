import axios from "axios";

/** 
 * Send Email via SMTP2Go
 * @since 1.0
 * @async
 **/

const sendEmail = async ({ recipient, subject, body }) => {
    try {
        const response = await axios.post('http://localhost:5001/api/v1/send-email', {
            to: recipient,
            subject: subject,
            text: body
        });

        // Check for success or log response
        if (response.status === 200) {
            console.log('Email sent successfully:', response.data);
        } else {
            console.error('Failed to send email:', response.data);
        }

    } catch (error) {
        console.error('Error sending email:', error.message);
    }
};

export default sendEmail;
