import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, ButtonGroup, Breadcrumb, Card, Form, Modal, Alert } from '@themesberg/react-bootstrap';
import { ToastContainer } from "react-toastify";
import { makeToast } from "../../../functions/toasts";
import { getSubscriptions } from "../../../api/private/subscriptions"; // Import the API function to get the user subscription data
import moment from 'moment'; // For date handling

const SubscriptionScreen = () => {
  const [packageDetails, setPackageDetails] = useState({
    name: '',
    price: 0,
    renewalDate: '',
    addons: [],
  });

  const [restrictions, setRestrictions] = useState([
    { name: "companies", min: 1, max: 10, price: 0 },
    { name: "brands", min: 1, max: 10, price: 0 },
    { name: "users", min: 1, max: 100, price: 0 },
    { name: "products", min: 1, max: 1000, price: 0 },
    { name: "leadChats", min: 1, max: 50, price: 0 }
  ]);

  const [showCancelModal, setShowCancelModal] = useState(false); // State to show/hide cancel modal
  const [feedback, setFeedback] = useState(''); // Feedback from the user
  const [reason, setReason] = useState(''); // Reason for cancellation
  const [alertMessage, setAlertMessage] = useState(''); // Alert message
  const [isCanceled, setIsCanceled] = useState(false); // State to handle canceled status

  const cancellationDate = packageDetails.renewalDate ? moment(packageDetails.renewalDate).add(1, 'months').format('YYYY-MM-DD') : ''; // Cancellation date calculation

  const loadUserSubscription = () => {
    getSubscriptions() // No need to pass userId here
      .then((_resp) => {
        if (_resp?.data?.Record) {
          const userSubscription = _resp.data.Record;

          const packageData = userSubscription.package || {};
          const restrictionsData = userSubscription.restrictions || [];

          setPackageDetails({
            name: packageData.name || '',
            price: parseFloat(packageData.price) || 0,
            renewalDate: userSubscription.renewal_date || '',
            addons: Object.entries(packageData.addons || {}).map(([key, value]) => ({
              name: key,
              price: parseFloat(value.price) || 0
            }))
          });

          setRestrictions(
            restrictionsData.length > 0
              ? restrictionsData.map((restriction) => ({
                  name: restriction.name || '',
                  min: restriction.min || 1,
                  max: restriction.max || 10,
                  price: restriction.price || 0
                }))
              : [
                  { name: "companies", min: 1, max: 10, price: 0 },
                  { name: "brands", min: 1, max: 10, price: 0 },
                  { name: "users", min: 1, max: 100, price: 0 },
                  { name: "products", min: 1, max: 1000, price: 0 },
                  { name: "leadChats", min: 1, max: 50, price: 0 }
                ]
          );
        } else {
          makeToast('error', 'Subscription not found for the user');
        }
      })
      .catch((error) => {
        console.error("Error fetching subscription data:", error);
        makeToast('error', 'Failed to load subscription details');
      });
  };

  useEffect(() => {
    loadUserSubscription(); // Fetch subscription details when component mounts
  }, []);

  const handleSliderChange = (name, value) => {
    setRestrictions((prevRestrictions) =>
      prevRestrictions.map((restriction) =>
        restriction.name === name ? { ...restriction, price: value } : restriction
      )
    );
  };

  const handleSaveChanges = () => {
    console.log('Updated restrictions:', restrictions);
    makeToast('success', 'Subscription settings updated successfully');
  };

  const handleCancelSubscription = () => {
    setShowCancelModal(true); // Show cancellation modal
  };

  const confirmCancellation = () => {
    setIsCanceled(true);
    setShowCancelModal(false);
    setAlertMessage(`This account has been set to cancel on ${cancellationDate}`);
  };

  const handleReActivate = () => {
    setIsCanceled(false);
    setAlertMessage('');
    makeToast('success', 'Subscription re-activated successfully');
    // Add logic here to re-activate subscription in the backend
  };

  return (
    <>
      <ToastContainer hideProgressBar={true} theme="light" pauseOnHover autoClose={5000} />
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Subscriptions</Breadcrumb.Item>
            <Breadcrumb.Item active>Manage Subscription</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Manage Subscription</h4>
        </div>
      </div>

      {alertMessage && (
        <Alert variant="warning">
          {alertMessage}
        </Alert>
      )}

      <Row>
        <Col xs={12} md={6}>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body>
              <h5>Adjust Your Subscription</h5>
              {restrictions.map((restriction, index) => (
                <Form.Group as={Row} className="align-items-center mb-4" key={index}>
                  <Form.Label column xs={4} md={4}>
                    Number of {restriction.name.charAt(0).toUpperCase() + restriction.name.slice(1)}
                  </Form.Label>
                  <Col xs={8} md={8}>
                    <Form.Range
                      as="input"
                      type="range"
                      name={restriction.name}
                      min={restriction.min}
                      max={restriction.max}
                      value={restriction.price}
                      onChange={(e) => handleSliderChange(restriction.name, Number(e.target.value))}
                    />
                    <Form.Control
                      type="number"
                      value={restriction.price}
                      onChange={(e) => handleSliderChange(restriction.name, Number(e.target.value))}
                      name={restriction.name}
                      className="mt-2"
                    />
                  </Col>
                </Form.Group>
              ))}
              <Button variant="primary" onClick={handleSaveChanges}>
                Save Changes
              </Button>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} md={6}>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body>
              <h5>Current Package</h5>
              <p><strong>Package Name:</strong> {packageDetails.name || 'N/A'}</p>
              <p><strong>Price:</strong> ${packageDetails.price ? packageDetails.price.toFixed(2) : '0.00'} / month</p>
              <p><strong>Renewal Date:</strong> {packageDetails.renewalDate || 'N/A'}</p>
              <h6>Features:</h6>
              <ul>
                {packageDetails.addons.length > 0 ? packageDetails.addons.map((addon, index) => (
                  <li key={index}>{addon.name}: ${addon.price ? addon.price.toFixed(2) : '0.00'}</li>
                )) : <li>No addons available</li>}
              </ul>

              {!isCanceled ? (
                <Button variant="danger" onClick={handleCancelSubscription}>
                  Cancel
                </Button>
              ) : (
                <Button variant="success" onClick={handleReActivate}>
                  Re-activate
                </Button>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={showCancelModal} onHide={() => setShowCancelModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Cancellation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your subscription will be canceled effective on {cancellationDate}.</p>
          <Form.Group>
            <Form.Label>Reason for Cancellation</Form.Label>
            <Form.Control as="select" value={reason} onChange={(e) => setReason(e.target.value)}>
              <option value="">Select a reason</option>
              <option value="dontNeed">Don't need it anymore</option>
              <option value="noLeads">Was not getting enough leads</option>
              <option value="improvePlatform">The platform can be improved</option>
              <option value="cost">The cost of the platform is too much</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Additional Feedback</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCancelModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={confirmCancellation}>
            Confirm Cancellation
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SubscriptionScreen;
