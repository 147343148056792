import React from 'react'
import { Carousel, Accordion, Col, Row, Form, Modal, Card, Button, Container, InputGroup, ToggleButton, ButtonGroup, Table } from '@themesberg/react-bootstrap';
import { faAngleLeft, faQuestionCircle, faIdCard, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CompanyDetailsForm = ({ companyDetails, setCompanyDetails }) => (
    <Row>
      <Col md={6}>
        <Form.Group id="legal_name" className="mb-4">
          <Form.Label>Company Legal Name</Form.Label>
          <InputGroup>
            <Form.Control
              required
              type="text"
              placeholder="Company Legal Name"
              value={companyDetails.legal_name}
              onChange={e => setCompanyDetails({ ...companyDetails, legal_name: e.target.value })}
            />
          </InputGroup>
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group id="trading_name" className="mb-4">
          <Form.Label>Trading Name</Form.Label>
          <InputGroup>
            <Form.Control
              required
              type="text"
              placeholder="Trading Name"
              value={companyDetails.trading_name}
              onChange={e => setCompanyDetails({ ...companyDetails, trading_name: e.target.value })}
            />
          </InputGroup>
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group id="reg_number" className="mb-4">
          <Form.Label>Registration Number</Form.Label>
          <InputGroup>
            <Form.Control
              required
              type="text"
              placeholder="Registration Number"
              value={companyDetails.registration_number}
              onChange={e => setCompanyDetails({ ...companyDetails, registration_number: e.target.value })}
            />
          </InputGroup>
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group id="vat_number" className="mb-4">
          <Form.Label>VAT Number</Form.Label>
          <InputGroup>
            <Form.Control
              required
              type="text"
              placeholder="VAT Number"
              value={companyDetails.vat_number}
              onChange={e => setCompanyDetails({ ...companyDetails, vat_number: e.target.value })}
            />
          </InputGroup>
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group id="contact_number" className="mb-4">
          <Form.Label>Primary Contact Number</Form.Label>
          <InputGroup>
            <Form.Control
              required
              type="text"
              placeholder="Primary Contact Number"
              value={companyDetails.contact_number}
              onChange={e => setCompanyDetails({ ...companyDetails, contact_number: e.target.value })}
            />
          </InputGroup>
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group id="primary_email" className="mb-4">
          <Form.Label>Primary Contact Email</Form.Label>
          <InputGroup>
            <Form.Control
              required
              type="email"
              placeholder="Primary Contact Email"
              value={companyDetails.primary_email}
              onChange={e => setCompanyDetails({ ...companyDetails, primary_email: e.target.value })}
            />
          </InputGroup>
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group id="registered_address" className="mb-4">
          <Form.Label>Registered Address</Form.Label>
          <InputGroup>
            <Form.Control
              required
              type="text"
              placeholder="Registered Address"
              value={companyDetails.registered_address}
              onChange={e => setCompanyDetails({ ...companyDetails, registered_address: e.target.value })}
            />
          </InputGroup>
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group id="billing_address" className="mb-4">
          <Form.Label>Billing Address</Form.Label>
          <InputGroup>
            <Form.Control
              required
              type="text"
              placeholder="Billing Address"
              value={companyDetails.billing_address}
              onChange={e => setCompanyDetails({ ...companyDetails, billing_address: e.target.value })}
            />
          </InputGroup>
        </Form.Group>
      </Col>
      {/* Social Links */}
      <Col md={6}>
        <Form.Group id="linkedin" className="mb-4">
          <Form.Label>LinkedIn</Form.Label>
          <InputGroup>
            <Form.Control
              type="url"
              placeholder="LinkedIn Profile URL"
              value={companyDetails.social_links.linkedin}
              onChange={e => setCompanyDetails({
                ...companyDetails,
                social_links: { ...companyDetails.social_links, linkedin: e.target.value }
              })}
            />
          </InputGroup>
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group id="facebook" className="mb-4">
          <Form.Label>Facebook</Form.Label>
          <InputGroup>
            <Form.Control
              type="url"
              placeholder="Facebook Profile URL"
              value={companyDetails.social_links.facebook}
              onChange={e => setCompanyDetails({
                ...companyDetails,
                social_links: { ...companyDetails.social_links, facebook: e.target.value }
              })}
            />
          </InputGroup>
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group id="instagram" className="mb-4">
          <Form.Label>Instagram</Form.Label>
          <InputGroup>
            <Form.Control
              type="url"
              placeholder="Instagram Profile URL"
              value={companyDetails.social_links.instagram}
              onChange={e => setCompanyDetails({
                ...companyDetails,
                social_links: { ...companyDetails.social_links, instagram: e.target.value }
              })}
            />
          </InputGroup>
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group id="twitter" className="mb-4">
          <Form.Label>Twitter</Form.Label>
          <InputGroup>
            <Form.Control
              type="url"
              placeholder="Twitter Profile URL"
              value={companyDetails.social_links.twitter}
              onChange={e => setCompanyDetails({
                ...companyDetails,
                social_links: { ...companyDetails.social_links, twitter: e.target.value }
              })}
            />
          </InputGroup>
        </Form.Group>
      </Col>
    </Row>
  );
  export default CompanyDetailsForm;