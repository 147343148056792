// DocumentUploadContext.js
import React, { createContext, useContext, useState, useEffect } from "react";

const DocumentUploadContext = createContext();

export const DocumentUploadProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedDocs, setUploadedDocs] = useState(() => {
    return JSON.parse(localStorage.getItem("uploadedDocs")) || {};
  });
  // Flag to disable auto-opening once the user manually closes the modal.
  const [autoOpenDisabled, setAutoOpenDisabled] = useState(false);

  useEffect(() => {
    localStorage.setItem("uploadedDocs", JSON.stringify(uploadedDocs));
  }, [uploadedDocs]);

  const openModal = () => {
    console.log("openModal called");
    setIsModalOpen(true);
  };

  const closeModal = () => {
    console.log("closeModal called");
    setIsModalOpen(false);
    // When the user manually closes, disable auto-opening.
    setAutoOpenDisabled(true);
  };

  // Check if uploads are complete: all 5 required docs have been uploaded.
  const isUploadComplete = Object.keys(uploadedDocs).length === 5;

  return (
    <DocumentUploadContext.Provider
      value={{
        isModalOpen,
        openModal,
        closeModal,
        uploadedDocs,
        setUploadedDocs,
        isUploadComplete,
        autoOpenDisabled,
      }}
    >
      {children}
    </DocumentUploadContext.Provider>
  );
};

export const useDocumentUpload = () => useContext(DocumentUploadContext);
