import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const AuthenticatedRoute = ({ component: Component, isSignedIn, ...rest }) => {
  // Check if the path includes '/admin'
  const isAdminRoute = rest.path.startsWith('/admin');

  return (
    <Route
      {...rest}
      render={(props) =>
        isAdminRoute ? (
          isSignedIn ? (
            <Component {...props} />
          ) : (
            <Redirect to="/signin" />
          )
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default AuthenticatedRoute;
