import Sec from '../functions/advancedMath'; // Ensure this is the correct import for your encryption utility

export const user_data_key = 'userDetails';
export const api_token_key = 'FLC-JWT';

const localSessionApi = {
    setUserApiToken: (_token) => {
        console.warn(`localSessionApi -> setUserApiToken / TO BE REMOVED`);
        sessionStorage.setItem(api_token_key, _token);
    },

    getUserApiToken: () => {
        console.warn(`localSessionApi -> getUserApiToken / TO BE REMOVED`);
        const token = sessionStorage.getItem(api_token_key);
        if (token === null) {
            return null;
        } else {
            console.log("Token returned");
            return token;
        }
    },

    setWithExpiry: (key, value) => {
        console.log("Attempting to set key with expiry:", key, value);  // Added to verify input values
        try {
            const now = new Date();
            const item = {
                value: value,
                expiry: now.getTime() + (25 * 24 * 60 * 60 * 1000), // 25 days in milliseconds
            };
            localStorage.setItem(key, JSON.stringify(item));  // Temporarily bypass Sec.encryptData for debugging
            console.log("Set operation successful:", localStorage.getItem(key)); // Check what's actually stored
        } catch (error) {
            console.error("Error setting key with expiry:", key, error);
        }
    },

    getWithExpiry: (key) => {
        try {
            if (!key) {
                console.error("Key is undefined");
                return null;
            }

            const itemStr = localStorage.getItem(key);
            if (!itemStr) {
                console.log("No item found for key:", key);
                return null;
            }

            // Check if the data is encrypted or not
            if (isLikelyEncrypted(itemStr)) {
                // Decrypt the data
                const decryptedStr = Sec.decryptData(itemStr);
                const item = JSON.parse(decryptedStr);

                // Expiry check
                const now = new Date();
                if (now.getTime() > item.expiry) {
                    localStorage.removeItem(key);
                    console.log("Item has expired and has been removed from local storage.");
                    return null;
                }

                // Return the value of the stored token
                return item.value;
            } else {
                // Handle unencrypted data (for debugging or if stored without encryption)
                console.log("Data does not appear to be encrypted, processing unencrypted data:", itemStr);
                const item = JSON.parse(itemStr);

                // Expiry check
                const now = new Date();
                if (now.getTime() > item.expiry) {
                    localStorage.removeItem(key);
                    console.log("Item has expired and has been removed from local storage.");
                    return null;
                }

                // Return the value of the stored token
                return item.value;
            }
        } catch (error) {
            console.error("Failed to retrieve or parse the item from local storage:", error);
            if (key === user_data_key) {
                console.log("Redirecting due to critical data retrieval failure.");
                window.location.replace('/');
            }
            return null;
        }
    },

    removeItem: (key) => {
        try {
            localStorage.removeItem(key);
            console.log(`Removed item with key: ${key}`);
        } catch (error) {
            console.error(`Failed to remove item with key: ${key}`, error);
        }
    }
};

// Helper function to check if data is likely encrypted
function isLikelyEncrypted(str) {
    const base64Regex = /^(?:[A-Za-z0-9+\/]{4})*(?:[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=)?$/;
    return base64Regex.test(str);
}

export default localSessionApi;
