// DocumentUploadModal.js
import React, { useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "@themesberg/react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useDocumentUpload } from "../../contexts/DocumentUploadContext";

const requiredDocs = [
  "Company Registration Document",
  "VAT Registration Document",
  "Director's IDs",
  "Proof of Address",
  "Bank Letter"
];

const DocumentUploadModal = () => {
  const { isModalOpen, closeModal, uploadedDocs, setUploadedDocs, isUploadComplete } = useDocumentUpload();

  // Function to update user verification status if all required docs are uploaded
  const updateUserVerification = async () => {
    try {
      const response = await fetch('/api/users/verify', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          // The API expects an array of document objects.
          documents: Object.values(uploadedDocs)
        })
      });
      if (!response.ok) {
        console.error("Failed to verify user");
      } else {
        console.log("User verified successfully");
      }
    } catch (err) {
      console.error("Error verifying user:", err);
    }
  };

  // Auto-close the modal and update verification when all files have been uploaded.
  useEffect(() => {
    if (isUploadComplete) {
      console.log("Auto-close: All files uploaded, closing modal");
      updateUserVerification();
      closeModal();
    }
  }, [isUploadComplete, closeModal]);

  // onDrop function that converts the file to a base64 string and stores its info
  const onDrop = (acceptedFiles, docType) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const fileData = {
          fileName: file.name,
          fileSize: file.size,
          fileDate: new Date().toISOString(),
          fileContent: reader.result // Base64 string
        };
        setUploadedDocs(prevDocs => ({ ...prevDocs, [docType]: fileData }));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Modal
      size="lg"
      show={isModalOpen}
      onHide={closeModal}
      centered
      backdrop="static" // Disables closing by clicking outside (if desired)
      keyboard={true}   // Allows closing with Esc key
    >
      <Modal.Header closeButton>
        <Modal.Title>Upload Verification Documents</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {requiredDocs.map((doc, index) => (
            <UploadField 
              key={index} 
              label={doc} 
              onDrop={onDrop} 
              uploaded={uploadedDocs[doc]} 
            />
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const UploadField = ({ label, onDrop, uploaded }) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: files => onDrop(files, label),
    multiple: false,
    accept: ".pdf,.jpg,.png"
  });

  return (
    <Row className="mb-3">
      <Col>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <div
            {...getRootProps()}
            style={{
              border: "1px dashed #ccc",
              padding: "10px",
              textAlign: "center",
              cursor: "pointer"
            }}
          >
            <input {...getInputProps()} />
            {uploaded ? (
              <p className="mb-0">✅ {label} Uploaded</p>
            ) : (
              <p className="mb-0">
                Drag & drop {label} here or click to select a file
              </p>
            )}
          </div>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default DocumentUploadModal;
