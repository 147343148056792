import axios from "axios";
import { api_token_key } from "../../utils/constants";
import localSessionApi from "../../utils/constants";

const PROXY_ENDPOINT = process.env.REACT_APP_PROXY_ENDPOINT;

// Public POST request with withCredentials
export function doPublicPostRequest(_payload, _path) {
    const PROXY_TOKEN = localSessionApi.getWithExpiry(api_token_key);

    const headers = {
        'Content-Type': 'application/json',
    };

    if (PROXY_TOKEN) {
        headers['FLC-JWT'] = PROXY_TOKEN;
    }

    const config = {
        method: 'post',
        url: `${PROXY_ENDPOINT}${_path}`,
        timeout: 40000,
        withCredentials: true,  // <-- Added here
        headers: headers,
        data: _payload
    };

    return axios(config);
}

// Public GET request with withCredentials
export function doPublicGetRequest(_path) {
    const PROXY_TOKEN = localSessionApi.getWithExpiry(api_token_key);

    const headers = {
        'Content-Type': 'application/json',
    };

    if (PROXY_TOKEN) {
        headers['FLC-JWT'] = PROXY_TOKEN;
    }

    const config = {
        method: 'get',
        url: `${PROXY_ENDPOINT}${_path}`,
        timeout: 40000,
        withCredentials: true,  // <-- Added here
        headers: headers
    };

    return axios(config);
}
