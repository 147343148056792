import React from 'react'
import { Carousel, Accordion, Col, Row, Form, Modal, Card, Button, Container, InputGroup, ToggleButton, ButtonGroup, Table } from '@themesberg/react-bootstrap';
import { faAngleLeft, faQuestionCircle, faIdCard, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PackageSelectionForm = ({ packages, selectedPackage, setSelectedPackage, isAnnual, setIsAnnual }) => {
    // Helper function to chunk packages into groups of 3
    const chunkPackages = (arr, chunkSize) => {
      let result = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        result.push(arr.slice(i, i + chunkSize));
      }
      return result;
    };
  
    // Filter packages based on the current isAnnual state (annual or monthly prices)
    const filteredPackages = packages.filter(pkg => 
      isAnnual ? pkg.annual_price : pkg.monthly_price
    );
    
    // Chunk the filtered packages into groups of 3
    const chunkedPackages = chunkPackages(filteredPackages, 3);
  
    return (
      <Row>
        <Col md={12} className="text-center mb-4">
          <ButtonGroup toggle>
            {/* Toggle button for Monthly Plans */}
            <ToggleButton
              type="radio"
              variant="outline-primary"
              name="radio"
              value={false}
              checked={!isAnnual}
              onChange={() => setIsAnnual(false)} // Set to false for monthly prices
            >
              Monthly Plans
            </ToggleButton>
  
            {/* Toggle button for Annual Plans */}
            <ToggleButton
              type="radio"
              variant="outline-primary"
              name="radio"
              value={true}
              checked={isAnnual}
              onChange={() => setIsAnnual(true)} // Set to true for annual prices
            >
              Annual Plans
            </ToggleButton>
          </ButtonGroup>
        </Col>
  
        <Col md={12}>
          <Form.Group id="package_selection" className="mb-4">
            <Form.Label>Select a Package</Form.Label>
            {chunkedPackages.length > 0 ? (
              <Carousel interval={null} indicators={true} controls={true}>
                {chunkedPackages.map((packageChunk, chunkIndex) => (
                  <Carousel.Item key={chunkIndex}>
                    <Row>
                      {packageChunk.map((pkg, index) => (
                        <Col key={pkg._id} md={6}>
                          <Card className={`p-3 package-card ${selectedPackage === pkg._id ? 'border-primary' : ''}`}>
                            <Card.Body>
                              <Card.Title>{pkg.title}</Card.Title>
                              <Card.Text>
                                <strong>{isAnnual ? "Annual Price" : "Monthly Price"}: </strong>
                                R{isAnnual ? pkg.annual_price : pkg.monthly_price}
                                <br />
                                <strong>Features:</strong>
                                <ul>
                                  {pkg.features.map((feature, idx) => (
                                    <li key={idx}>{feature.text}</li>
                                  ))}
                                </ul>
                              </Card.Text>
                              <Button
                                variant={selectedPackage === pkg._id ? 'primary' : 'outline-primary'}
                                onClick={() => setSelectedPackage(pkg._id)}
                              >
                                {selectedPackage === pkg._id ? 'Selected' : 'Select'}
                              </Button>
                            </Card.Body>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Carousel.Item>
                ))}
              </Carousel>
            ) : (
              <p>No packages available at the moment.</p>
            )}
          </Form.Group>
        </Col>
      </Row>
    );
  };  
  export default PackageSelectionForm;