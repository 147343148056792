import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, InputGroup, Tabs, Tab } from '@themesberg/react-bootstrap';
import { updateUser, getUser } from '../api/private/users'; 
import { makeToast } from '../functions/toasts';

export const GeneralInfoForm = () => {
  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    email_address: "",
    company_position: "",
    user_role: "",
    profile_image: "",
    birthday: "" // Include birthday in the main user details state
  });
  const [docId, setDocId] = useState(""); // State to hold user document ID

  useEffect(() => {
    // Fetch user details from localStorage
    const storedUserDetails = localStorage.getItem("user_details");
    if (storedUserDetails) {
      const parsedUserDetails = JSON.parse(storedUserDetails);
      setUserDetails({
        ...parsedUserDetails,
        birthday: parsedUserDetails.birthday || "" // Set birthday from stored details
      });
      setDocId(parsedUserDetails._id || "");
    }
  }, []); // Run only once on component mount

  const handleSave = async () => {
    if (!docId) {
      console.error("User ID is not available for update.");
      makeToast("error", "User ID not found, cannot update the user.");
      return;
    }
  
    try {
      // Fetch the latest document from the server to get the latest _rev
      const currentUserResponse = await getUser(docId);
      if (currentUserResponse.data.Result !== 'OK') {
        console.error("Failed to fetch current user document:", currentUserResponse.data);
        makeToast("error", "Failed to fetch current user document.");
        return;
      }
  
      const currentUser = currentUserResponse.data.Record;

      // Prepare updated user details with the latest _rev
      const updatedUserDetails = {
        ...currentUser, // Include all current fields, including the latest _rev
        ...userDetails, // Override with new values from state
        type: "user" // Ensure 'type' is set to 'user'
      };
  
      console.log("Updating user with details:", updatedUserDetails); // Debugging
  
      const response = await updateUser(docId, updatedUserDetails);
  
      if (response.data.Result === 'OK') {
        makeToast("success", "User details updated successfully.");
        // Update localStorage with the updated details
        localStorage.setItem('user_details', JSON.stringify(updatedUserDetails));
      } else {
        console.error(response.data);
        makeToast("error", `Failed to update user details. ${response.data.SysMsg || ''}`);
      }
    } catch (error) {
      console.error("Error updating user details:", error);
      makeToast("error", "An error occurred while updating user details.");
    }
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form>
          <Tabs defaultActiveKey="personalInfo" id="info-tabs" className="mb-3">
            <Tab eventKey="personalInfo" title="Personal Information">
              <h5 className="mb-4">General information</h5>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group id="firstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter your first name"
                      value={userDetails.first_name || ""} // Use value from state with fallback
                      onChange={(e) =>
                        setUserDetails({ ...userDetails, first_name: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="lastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Also your last name"
                      value={userDetails.last_name || ""} // Use value from state with fallback
                      onChange={(e) =>
                        setUserDetails({ ...userDetails, last_name: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col md={6} className="mb-3">
                  <Form.Group id="birthday">
                    <Form.Label>Birthday</Form.Label>
                    <Datetime
                      timeFormat={false}
                      onChange={(date) =>
                        setUserDetails({ ...userDetails, birthday: date })
                      }
                      value={userDetails.birthday || ""} // Use state value with fallback
                      renderInput={(props, openCalendar) => (
                        <InputGroup>
                          <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                          <Form.Control
                            required
                            type="text"
                            value={userDetails.birthday ? moment(userDetails.birthday).format("MM/DD/YYYY") : ""}
                            placeholder="mm/dd/yyyy"
                            onFocus={openCalendar}
                            onChange={() => { }} />
                        </InputGroup>
                      )} />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="mobileNumber">
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter your mobile number"
                      value={userDetails.mobile_number || ""} // Use value from state with fallback
                      onChange={(e) =>
                        setUserDetails({ ...userDetails, mobile_number: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="mb-3">
                  <Form.Group id="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      placeholder="name@company.com"
                      value={userDetails.email_address || ""} // Use value from state with fallback
                      onChange={(e) =>
                        setUserDetails({ ...userDetails, email_address: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="phone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder="+12-345 678 910"
                      value={userDetails.mobile_number || ""} // Use value from state with fallback
                      onChange={(e) =>
                        setUserDetails({ ...userDetails, mobile_number: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <h5 className="my-4">Address</h5>
              <Row>
                <Col sm={9} className="mb-3">
                  <Form.Group id="address">
                    <Form.Label>Address</Form.Label>
                    <Form.Control required type="text" placeholder="Enter your home address" />
                  </Form.Group>
                </Col>
                <Col sm={3} className="mb-3">
                  <Form.Group id="addressNumber">
                    <Form.Label>Number</Form.Label>
                    <Form.Control required type="number" placeholder="No." />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={8} className="mb-3">
                  <Form.Group id="city">
                    <Form.Label>City</Form.Label>
                    <Form.Control required type="text" placeholder="City" />
                  </Form.Group>
                </Col>
                <Col sm={4} className="mb-3">
                  <Form.Group id="zip">
                    <Form.Label>ZIP</Form.Label>
                    <Form.Control required type="tel" placeholder="ZIP" />
                  </Form.Group>
                </Col>
              </Row>
            </Tab>

            <Tab eventKey="companyInfo" title="Company Information">
              <h5 className="mb-4">Company Details</h5>
              <Row>
                <Col sm={6} className="mb-3">
                  <Form.Group id="company-name">
                    <Form.Label>Registered Name</Form.Label>
                    <Form.Control required type="text" placeholder="Enter your company name" />
                  </Form.Group>
                </Col>
                <Col sm={6} className="mb-3">
                  <Form.Group id="vatNumber">
                    <Form.Label>VAT Number</Form.Label>
                    <Form.Control required type="number" placeholder="Vat No." />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="mb-3">
                  <Form.Group id="company-reg">
                    <Form.Label>Company Registration</Form.Label>
                    <Form.Control required type="text" placeholder="Your Company Reg" />
                  </Form.Group>
                </Col>
                <Col sm={6} className="mb-3">
                  <Form.Group className="mb-2">
                    <Form.Label>Company Type</Form.Label>
                    <Form.Select id="company-type" defaultValue="0">
                      <option value="CC">Close Corporation</option>
                      <option value="PTY">Proprietary Limited</option>
                      <option value="SOLE-PROP">Sole Prop</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group id="company-tel">
                    <Form.Label>Company Contact Number</Form.Label>
                    <Form.Control required type="tel" placeholder="Your company number" />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group id="company-email">
                    <Form.Label>Company Email</Form.Label>
                    <Form.Control required type="email" placeholder="Your company email" />
                  </Form.Group>
                </Col>
              </Row>
            </Tab>
          </Tabs>
          <div className="mt-3">
            <Button variant="primary" type="button" onClick={handleSave}>Save All</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};
