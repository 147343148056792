import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest, doPrivatePutRequest } from "./requests";
import { api_token_key } from '../../utils/constants';

export const createBrand = (payload) => {
    return doPrivatePostRequest(payload, 'brands')
}

export const updateBrand = (_doc_id, payload) => {
    return doPrivatePutRequest(payload, `brands/update/${_doc_id}`)
}

export const getBrand = (_doc_id) => {
    return doPrivatePostRequest(`brands/get/${_doc_id}`)
}

export const getAllBrands = () => {
    return doPrivateGetRequest('brands')
}

export const deleteBrand = (_doc_id) => {
    return doPrivateDelRequest(`brands/delete/${_doc_id}`)
}
