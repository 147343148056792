import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisH, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Card, Table, Dropdown, ButtonGroup, Pagination, Button, Form } from '@themesberg/react-bootstrap';

export const UsersTables = ({ users, deleteUser, handleEditUser }) => {

  const ITEMS_PER_PAGE = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    companyPosition: "",
    name: "",
    dateCreated: "",
    primaryContact: "",
    userType: ""
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const filteredUsers = users.filter(user => {
    const name = `${user.first_name} ${user.last_name}`;
    const primaryContact = `${user.email_address} / ${user.mobile_number}`;
    const userTypeValue = user.userType || user.user_role || "";
    return (
      (filters.companyPosition === "" || (user.company_position ?? "").toLowerCase().includes(filters.companyPosition.toLowerCase())) &&
      (filters.name === "" || name.toLowerCase().includes(filters.name.toLowerCase())) &&
      (filters.dateCreated === "" || (user.created_on ?? "").toLowerCase().includes(filters.dateCreated.toLowerCase())) &&
      (filters.primaryContact === "" || primaryContact.toLowerCase().includes(filters.primaryContact.toLowerCase())) &&
      (filters.userType === "" || userTypeValue.toLowerCase().includes(filters.userType.toLowerCase()))
    );
  });

  const currentTableData = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filteredUsers.slice(startIndex, endIndex);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageCount = Math.ceil(filteredUsers.length / ITEMS_PER_PAGE);
  const items = [];
  for (let number = 1; number <= pageCount; number++) {
    items.push(
      <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
        {number}
      </Pagination.Item>
    );
  }

  const TableRow = ({
    _id,
    first_name,
    last_name,
    company_position,
    created_on,
    isVerified,
    email_address,
    mobile_number,
    userType,
    user_role,
    verificationDocuments
  }) => {
    const name = `${first_name} ${last_name}`;
    const displayUserType = userType || user_role || "-";
    const verifiedDocsCount = verificationDocuments ? verificationDocuments.length : 0;
    return (
      <tr>
        <td>{company_position}</td>
        <td className="fw-bold" onClick={() => handleEditUser({ _id, first_name, last_name, company_position, created_on, email_address, mobile_number, userType, user_role, verificationDocuments })}>{name}</td>
        <td>{created_on}</td>
        <td>
          <span
            style={{
              backgroundColor: isVerified ? 'yellow' : 'red',
              color: isVerified ? 'black' : 'white',
              padding: '5px 10px',
              borderRadius: '5px',
              fontWeight: 'bold'
            }}
          >
            {isVerified ? 'Verified' : 'Not Verified'}
          </span>
        </td>
        <td>{displayUserType}</td>
        <td>{verifiedDocsCount > 0 ? verifiedDocsCount : '-'}</td>
        <td>{email_address} / {mobile_number}</td>
        <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleEditUser({ _id, first_name, last_name, company_position, created_on, email_address, mobile_number, userType, user_role, verificationDocuments })}>
                <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
              </Dropdown.Item>
              <Dropdown.Item className="text-danger" onClick={() => deleteUser(_id)}>
                <FontAwesomeIcon icon={faTrashAlt} className="me-2" /> Remove
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm">
      <Card.Body className="pb-0">
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th>Company Position</th>
              <th>Name</th>
              <th>Date Created</th>
              <th>Status</th>
              <th>User Type</th>
              <th>Verified Documents</th>
              <th>Primary Contact</th>
              <th>Action</th>
            </tr>
            <tr>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Company Position"
                  name="companyPosition"
                  value={filters.companyPosition}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Name"
                  name="name"
                  value={filters.name}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Date Created"
                  name="dateCreated"
                  value={filters.dateCreated}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Status"
                  name="status"
                  value={filters.status || ""}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by User Type"
                  name="userType"
                  value={filters.userType || ""}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th></th>
              <th>
                <Form.Control
                  type="text"
                  placeholder="Filter by Primary Contact"
                  name="primaryContact"
                  value={filters.primaryContact}
                  onChange={handleFilterChange}
                  className="mt-2"
                />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.length > 0 ? (
              currentTableData().map(user => <TableRow key={`user-${user._id}`} {...user} handleEditUser={handleEditUser} deleteUser={deleteUser} />)
            ) : (
              <tr>
                <td colSpan="8" className="text-center">No users found.</td>
              </tr>
            )}
          </tbody>
        </Table>
        <Pagination className="mt-4 justify-content-center">{items}</Pagination>
      </Card.Body>
    </Card>
  );
};
