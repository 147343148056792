import React from 'react';
import { Link } from 'react-router-dom';
import { Routes } from "../../routes";
import { Col, Button, Row, Card, Image, Container, Navbar } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faBuilding, faBook, faWrench } from "@fortawesome/free-solid-svg-icons";

export const Footer = () => {
  return (
    <>
      {/* Desktop Footer */}
      <footer className="footer py-6 bg-primary text-white d-none d-md-block">
        <Container>
          <Row>
            <Col md={4}>
              <Navbar.Brand as={Link} to="#home" className="me-lg-3 mb-3 d-flex align-items-center">
                <span className="mb-2 brand-text" link={Routes.Presentation.path}><img src="../../assets/img/Forklifting.co-logo1.png" /></span>
              </Navbar.Brand>
              <p>We leverage technology to provide you with the confidence that you need to make forklift buying and related services decisions easier.</p>
            </Col>
            <Col xs={6} md={2} className="mb-5 mb-lg-0">
              <span className="h5">Useful Links</span>
              <ul className="links-vertical mt-2">
                <li><Card.Link as={Link} to={Routes.Insights.path}>Insights</Card.Link></li>
                <li><Card.Link as={Link} to={Routes.FrequentlyAsked.path}>FAQ</Card.Link></li>
                <li><Card.Link as={Link} to={Routes.About.path}>About Us</Card.Link></li>
                <li><Card.Link as={Link} to={Routes.Contact.path}>Contact Us</Card.Link></li>
              </ul>
            </Col>
            <Col xs={6} md={2} className="mb-5 mb-lg-0">
              <span className="h5">Quick Navigation</span>
              <ul className="links-vertical mt-2">
                <li><Card.Link as={Link} to={Routes.ForSuppliers.path}>For Suppliers</Card.Link></li>
                <li><Card.Link as={Link} to={Routes.Careers.path}>Careers</Card.Link></li>
                <li><Card.Link as={Link} to={Routes.CompanySearch.path}>Companies</Card.Link></li>
                <li><Card.Link as={Link} to={Routes.Brands.path}>Brands</Card.Link></li>
                <li><Card.Link as={Link} to={Routes.ProductServices.path}>Products & Services</Card.Link></li>
                <li><Card.Link as={Link} to={Routes.Support.path}>Support</Card.Link></li>
              </ul>
            </Col>
            <Col xs={12} md={4} className="mb-5 mb-lg-0">
              <span className="h5 mb-3 d-block">Subscribe Now</span>
              <form action="#">
                <div className="form-row mb-2">
                  <div className="col-12">
                    <input type="email" className="form-control mb-2" placeholder="example@company.com" name="email" aria-label="Subscribe form" required />
                  </div>
                  <div className="col-12">
                    <Button type="submit" variant="secondary" className="btn text-white shadow-soft btn-block" data-loading-text="Sending">
                      <span>Subscribe</span>
                    </Button>
                  </div>
                </div>
              </form>
              <p className="text-muted font-small m-0">We’ll never share your details. See our <Card.Link className="text-white" href="#">Privacy Policy</Card.Link></p>
            </Col>
          </Row>
          <hr className="bg-gray my-5" />
          <Row>
            <Col className="mb-md-2">
              <div className="d-flex text-center justify-content-center align-items-center" role="contentinfo">
                <p className="font-weight-normal font-small mb-0">Copyright © Forklifting.co 2023-<span className="current-year">2024</span>. All rights reserved.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>

      {/* Mobile Footer */}
      <footer className="mobile-footer bg-primary d-md-none border-none">
        <Container className="d-flex justify-content-around align-items-center py-3">
          <Link to="/" className="text-white text-center">
            <FontAwesomeIcon icon={faHome} size="lg" />
            <p className="mb-0 small">Home</p>
          </Link>
          <Link to="/companies" className="text-white text-center">
            <FontAwesomeIcon icon={faBuilding} size="lg" />
            <p className="mb-0 small">Companies</p>
          </Link>
          <Link to="/brands" className="text-white text-center">
            <FontAwesomeIcon icon={faBook} size="lg" />
            <p className="mb-0 small">Brands</p>
          </Link>
          <Link to="/products" className="text-white text-center">
            <FontAwesomeIcon icon={faWrench} size="lg" />
            <p className="mb-0 small">Products</p>
          </Link>
        </Container>
      </footer>
    </>
  );
};
