import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Button, Modal, Tabs, Tab } from '@themesberg/react-bootstrap';
import { FaTimes } from 'react-icons/fa';

const AddUserModal = ({ showModal, handleCloseModal, createUserAcc, updateUserAcc, initialUserData, isEditMode }) => {
  const [userData, setUserData] = useState({
    first_name: '',
    last_name: '',
    email_address: '',
    mobile_number: '',
    password: '',
    userType: '',
    isVerified: false,
  });

  useEffect(() => {
    console.log("Modal State:", { showModal, isEditMode, initialUserData });
    if (showModal) {
      if (isEditMode && initialUserData) {
        setUserData({
          id: initialUserData._id || '',
          first_name: initialUserData.first_name || '',
          last_name: initialUserData.last_name || '',
          email_address: initialUserData.email_address || '',
          mobile_number: initialUserData.mobile_number || '',
          // Leave the password field empty for security reasons
          password: '',
          userType: initialUserData.userType || '',
          // When editing, use the database value directly for isVerified:
          isVerified: initialUserData.isVerified === true || initialUserData.isVerified === 'true',
        });
      } else {
        setUserData({
          first_name: '',
          last_name: '',
          email_address: '',
          mobile_number: '',
          password: '',
          userType: '',
          isVerified: false,
        });
      }
    }
    // Include initialUserData in dependencies so that when editing the modal reflects the database value.
  }, [showModal, isEditMode, initialUserData]);

  function onKeyValueChange(key, value) {
    setUserData((prevUserData) => ({
      ...prevUserData,
      [key]: value,
    }));
  }

  return (
    <>
      <Modal size="lg" show={showModal} onHide={handleCloseModal}>
        <Modal.Header className="d-flex justify-content-between align-items-center">
          <Modal.Title>{isEditMode ? 'Edit User' : 'Add User'}</Modal.Title>
          <div className="d-flex align-items-center">
            <span
              style={{
                backgroundColor: userData.isVerified ? 'yellow' : 'red',
                color: userData.isVerified ? 'black' : 'white',
                padding: '5px 10px',
                borderRadius: '5px',
                marginRight: '10px',
              }}
            >
              {userData.isVerified ? 'Verified' : 'Not Verified'}
            </span>
            <Button variant="link" onClick={handleCloseModal}>
              <FaTimes />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Tabs defaultActiveKey="info" id="user-modal-tabs" className="mb-3">
            <Tab eventKey="info" title="User Info">
              <Form>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="first_name">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter name"
                        value={userData.first_name}
                        onChange={(e) => onKeyValueChange('first_name', e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="last_name">
                      <Form.Label>Surname</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter surname"
                        value={userData.last_name}
                        onChange={(e) => onKeyValueChange('last_name', e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="email_address">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        required
                        type="email"
                        placeholder="Enter email address"
                        value={userData.email_address}
                        onChange={(e) => onKeyValueChange('email_address', e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group controlId="mobile_number">
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter mobile number"
                        value={userData.mobile_number}
                        onChange={(e) => onKeyValueChange('mobile_number', e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {/* New Fields */}
                <Row>
                  <Col md={4} className="mb-3">
                    <Form.Group controlId="password">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter new password"
                        value={userData.password}
                        onChange={(e) => onKeyValueChange('password', e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group controlId="userType">
                      <Form.Label>User Type</Form.Label>
                      <Form.Select
                        value={userData.userType}
                        onChange={(e) => onKeyValueChange('userType', e.target.value.toLowerCase())}
                      >
                        <option value="">Select user type</option>
                        <option value="admin">Admin</option>
                        <option value="employee">Employee</option>
                        <option value="buyer">Buyer</option>
                        <option value="supplier">Supplier</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group controlId="verified">
                      <Form.Label>Verified</Form.Label>
                      <Form.Select
                        value={userData.isVerified ? 'true' : 'false'}
                        onChange={(e) => onKeyValueChange('isVerified', e.target.value === 'true')}
                      >
                        <option value="true">Verified</option>
                        <option value="false">Not Verified</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Tab>
            <Tab eventKey="files" title="Files">
              <>
                {/* Always display the header row */}
                <Row className="mb-2 font-weight-bold">
                  <Col md={2}>Thumbnail</Col>
                  <Col md={3}>File Name</Col>
                  <Col md={2}>File Size</Col>
                  <Col md={3}>Date Added</Col>
                  <Col md={2}>Actions</Col>
                </Row>
                {initialUserData &&
                initialUserData.verifiedDocuments &&
                initialUserData.verifiedDocuments.length > 0 ? (
                  initialUserData.verifiedDocuments.map((file, index) => (
                    <Row key={index} className="mb-3 align-items-center">
                      <Col md={2}>
                        {file.fileThumbnail ? (
                          <img
                            src={file.fileThumbnail}
                            alt="Thumbnail"
                            style={{ width: '50px', height: '50px' }}
                          />
                        ) : (
                          <div style={{ width: '50px', height: '50px', backgroundColor: '#ccc' }} />
                        )}
                      </Col>
                      <Col md={3}>
                        <strong>{file.fileName}</strong>
                      </Col>
                      <Col md={2}>
                        <span>{file.fileSize} bytes</span>
                      </Col>
                      <Col md={3}>
                        <span>{new Date(file.fileDate).toLocaleString()}</span>
                      </Col>
                      <Col md={2}>
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() => window.open(file.fileContent, '_blank')}
                        >
                          View
                        </Button>{' '}
                        <Button
                          variant="outline-danger"
                          size="sm"
                          onClick={() => console.log('Decline file', file)}
                        >
                          Decline
                        </Button>{' '}
                        <Button
                          variant="outline-success"
                          size="sm"
                          onClick={() => console.log('Accept file', file)}
                        >
                          Accept
                        </Button>
                      </Col>
                    </Row>
                  ))
                ) : (
                  <p>No files uploaded.</p>
                )}
              </>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="brand-orange"
            onClick={() => (isEditMode ? updateUserAcc(userData) : createUserAcc(userData))}
          >
            {isEditMode ? 'Update User' : 'Add User'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddUserModal;
