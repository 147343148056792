import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, Dropdown } from '@themesberg/react-bootstrap';
import { getAllBrands, createBrand, updateBrand, deleteBrand } from "../../../api/private/brands";
import { createOfferings } from "../../../api/private/offerings";
import { BrandsTables } from "../../../components/tables/BrandsTables";
import { makeToast } from "../../../functions/toasts";
import AddBrandModal from "../../../components/modals/AddBrandModal";
import { ToastContainer, toast } from "react-toastify";

export default () => {
  const [brands, setBrands] = useState([]);
  const [initialBrandData, setInitialBrandData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    loadBrands();
  }, []);

  function createOffering(offeringData) {
    const payload = {
      'offering': offeringData['offering'],
      'offering_content': offeringData['offering_content'],
      'rental_service': offeringData['rental_service'],
      'maintenance_and_repairs': offeringData['maintenance_and_repairs'],
      'training_programmings': offeringData['training_progreammings'],
      'spare_parts_and_accessories': offeringData['spare_parts_and_accessories'],
      'emergency_services_and_response': offeringData['emergency_services_and_response'],
      'technology_solutions': offeringData['technology_solutions'],
      'refurbished_solutions': offeringData['refurbished_solutions'],
      'customized_solutions': offeringData['customized_solution'],
      'compliance_and_certification_services': offeringData['compliance_and_certification_services'],
      'warranty_offered': offeringData['warranty_offered'],
      'notes': offeringData['notes']
    };

    createOfferings(payload).then((_resp) => {
      if (_resp.data.Result === 'OK' || _resp.data.Result === 'SUCCESS') {
        toast.success('Created new offering');
        handleCloseModal();
        setShowModal(false);
      } else {
        console.warn(_resp.data);
        toast.error('Failed to create offering')
      }
    }).catch((_resp) => {
      console.log(_resp);
      toast.error('Failed to create offering');
    });
  }

  function loadBrands() {
    getAllBrands().then((_resp) => {
      if (_resp.data.Result === 'OK' || _resp.data.Result === 'SUCCESS') {
        const records = _resp.data.Records;
        setBrands(records);
        toast.success(`Loaded brands successfully`);
      } else {
        console.warn(_resp.data);
        toast.error(`Failed to load brands`);
      }
    }).catch((_resp) => {
      console.log(_resp);
      makeToast('error', 'Failed to load brands');
    });
  }

  function createBrands(brandData) {
    const payload = {
      'brand_name': brandData['brand_name'],
      'brand_image': brandData['brand_image'],
      'brand_location': brandData['brand_location'],
      'brand_description': brandData['brand_description'],
      'created_by': {
        'doc_id': '',
        'email': ''
      },
      'linked_user': Array.isArray(brandData['linked_user']) ? brandData['linked_user'].map(user => ({
        'doc_id': user?.doc_id || '',
        'email': user?.email || ''
      })) : [],
      'founded': brandData['founded'],
      'meta': Array.isArray(brandData['meta']) ? brandData['meta'].map(metaItem => ({
        address: metaItem?.address || '',
        contact_number: metaItem?.contact_number || '',
        bbeee_level: metaItem?.bbeee_level || '',
        bbeee_validity: metaItem?.bbeee_validity || '',
        website: metaItem?.website || '',
        brand_logo: metaItem?.brand_logo || ''
      })) : []
    };

    createBrand(payload).then((_resp) => {
      if (_resp.data.Result === 'OK' || _resp.data.Result === 'SUCCESS') {
        toast.success(`${_resp.data?.message}`);
        handleCloseModal();
        setShowModal(false);
        loadBrands();
      } else {
        console.warn(_resp.data);
        toast.error(`${_resp.data?.message}`);
        loadBrands();
      }
    }).catch((_resp) => {
      console.log(_resp);
      toast.error('Failed to create brand');
    });
  }

  function updateBrands(brand, brandData) {
    console.log("Update function ran with object:", brand);

    const payload = {
      '_id': brandData['_id'],
      'brand_name': brandData['brand_name'],
      'brand_image': brandData['brand_image'],
      'brand_location': brandData['brand_location'],
      'brand_description': brandData['brand_description'],
      'created_by': {
        'doc_id': '',
        'email': ''
      },
      'linked_user': Array.isArray(brandData['linked_user']) ? brandData['linked_user'].map(user => ({
        doc_id: user?.doc_id || '',
        email: user?.email || ''
      })) : [],
      'founded': brandData['founded'],
      'meta': Array.isArray(brandData['meta']) ? brandData['meta'].map(metaItem => ({
        address: metaItem?.address || '',
        contact_number: metaItem?.contact_number || '',
        bbeee_level: metaItem?.bbeee_level || '',
        bbeee_validity: metaItem?.bbeee_validity || '',
        website: metaItem?.website || '',
        brand_logo: metaItem?.brand_logo || ''
      })) : []
    };

    updateBrand(brandData['_id'], payload).then((_resp) => {
      if (_resp.data.Result === 'OK' || _resp.data.Result === 'SUCCESS') {
        handleCloseModal();
        toast.success(`${_resp.data.Message}`);
        setShowModal(false);
        loadBrands();
        console.log(_resp.data);
      } else {
        console.warn(_resp.data);
        handleCloseModal();
        toast.error(`${_resp.data.Message}`);
        loadBrands();
      }
    }).catch((_resp) => {
      console.log(_resp);
      handleCloseModal();
      toast.error('Failed to update brand');
    });
  }

  function deleteBrands(brands) {
    deleteBrand(brands).then((_resp) => {
      if (_resp.data.Result === 'OK' || _resp.data.Result === 'SUCCESS') {
        toast.success(`${_resp.data.Message}`);
        loadBrands();
      } else {
        console.warn(_resp.data);
        toast.error(`${_resp.data.Message}`);
        loadBrands();
      }
    }).catch((_resp) => {
      console.log(_resp);
      toast.error('Failed to delete brand');
    });
  }

  const handleEditBrand = (brand) => {
    console.log(brand);
    setInitialBrandData(brand);
    setIsEditMode(true);
    setShowModal(true);
  };

  const handleAddBrand = () => {
    setInitialBrandData(null);
    setIsEditMode(false);
    setShowModal(true);
  };

  return (
    <>
      <ToastContainer hideProgressBar={true} theme="light" pauseOnHover autoClose={5000}/>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Brands</Breadcrumb.Item>
            <Breadcrumb.Item active>Brands</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Brands</h4>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          <ButtonGroup>
            <Button variant="outline-primary" size="sm">Share</Button>
            <Button variant="outline-primary" size="sm">Export</Button>
            <Button className="btn btn-primary" onClick={handleAddBrand}>Add Brand</Button>
          </ButtonGroup>
        </div>
      </div>

      <BrandsTables brands={brands} deleteBrands={deleteBrands} handleEditBrand={handleEditBrand} />
      <AddBrandModal
        createOffering={createOffering}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        createBrands={createBrands}
        updateBrands={updateBrands}
        initialBrandData={initialBrandData}
        isEditMode={isEditMode}
      />
    </>
  );
};
