import React, { useState, useEffect } from "react";
import { Col, Row, Breadcrumb, Container } from '@themesberg/react-bootstrap';
import { CompanyCard } from '../../../components/CompanyCard';
import { getAllPages } from "../../../api/private/pages";
import { NavigationBar } from "../../../components/frontendNav/Navigation";
import { Footer } from '../../../components/frontendNav/Footer';
import analytics from '../../../functions/analytics';

export default () => {

  const [page, setPage] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadPages();
    analytics.page();
  }, []);

  function loadPages() {
    getAllPages().then(response => {
      if (response.data && response.data.Result === 'OK') {
        const records = response.data.Records;
        const foundPage = records.find(p => p.slug === "companies");
        if (foundPage) {
          setPage(foundPage);
          setLoading(false);
          console.log(`Page found:`, foundPage);
        } else {
          console.warn(`Page with slug "companies" not found.`);
        }
      } else {
        console.warn('Unexpected response structure:', response);
      }
    }).catch(error => {
      console.error('Error loading pages:', error);
    }).finally(() => {
      setLoading(false);
    });
  }

  return (
    <>
      {/*-- Navigation Bar --*/}
      <NavigationBar />
      {loading ? (
        <Row className="justify-content-center">
          <Col>Loading...</Col>
        </Row>
      ) : (
        <>
          <section className="partner-brands text-center align-items-center justify-content-center overflow-hidden pt-5 pt-lg-6 pb-6 pb-lg-6 bg-grey text-dark">
            <Container>
              {page && (
                <h1 className="fw-bold text-dark">{page['title']}</h1>
              )}
              <div className="d-block mb-4 mb-md-0 text-left">
                <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
                  <Breadcrumb.Item>You are here:</Breadcrumb.Item>
                  <Breadcrumb.Item>Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Suppliers</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              {page && (
                <p dangerouslySetInnerHTML={{ __html: page['page_content'] }} />
              )}
            </Container>
          </section>

          <div className="bg-primary table-settings pt-6 pb-6 px-3 px-lg-7">
            <Container>
              <Row className="justify-content-center align-items-center">
                <Col xs={12}>
                  <section className="text-center align-items-center justify-content-center overflow-hidden pt-4 pt-lg-4 pb-4 pb-lg-4 bg-primary text-white">
                    <div className="d-flex flex-column flex-md-row justify-content-center search-box">
                      <select className="form-select me-2 mb-2 mb-md-0">
                        <option value="">Filter by product or service</option>
                        {/* Replace these with your actual product options */}
                        <option value="product1">Product 1</option>
                        <option value="product2">Product 2</option>
                        <option value="product3">Product 3</option>
                      </select>

                      <select className="form-select me-2 mb-2 mb-md-0">
                        <option value="">Filter by nearest location</option>
                        {/* Replace these with your actual location options */}
                        <option value="location1">Location 1</option>
                        <option value="location2">Location 2</option>
                        <option value="location3">Location 3</option>
                      </select>

                      <select className="form-select me-2 mb-2 mb-md-0">
                        <option value="">Filter by BBEEE Level</option>
                        {/* Replace these with your actual location options */}
                        <option value="location1">Location 1</option>
                        <option value="location2">Location 2</option>
                        <option value="location3">Location 3</option>
                      </select>

                      <button className="btn btn-secondary">GO</button>
                    </div>
                  </section>
                </Col>
                <div className="background-overlay-med"></div>
              </Row>
            </Container>
          </div>

          {/*-- CompanyCard for Companies --*/}
          <Container fluid>
            <Row className="flex-wrap justify-content-center">
              <Col xs={12} md={6} lg={12} className="d-flex flex-column mb-4">
                <CompanyCard />
              </Col>
            </Row>
          </Container>

          {/*-- Footer --*/}
          <Footer />
        </>
      )}
    </>
  );
};
