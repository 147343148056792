import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest, doPrivatePutRequest } from "./requests";

export const createProduct = (payload) => {
    return doPrivatePostRequest(payload, 'listings')
}

export const updateProduct = (_doc_id, payload) => {
    return doPrivatePutRequest(payload, `listings/update/${_doc_id}`)
}

export const getProduct = (_doc_id) => {
    return doPrivatePostRequest(`listings/get/${_doc_id}`)
}

export const getAllProducts = () => {
    return doPrivateGetRequest('listings')
}

export const deleteProduct = (_doc_id) => {
    return doPrivateDelRequest(`listings/delete/${_doc_id}`)
}
