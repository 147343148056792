import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest, doPrivateFilePostRequest } from "./requests";

export const createImage = (_payload) => {
    const payload = {
        "image_name": _payload['image_name'],
        "caption": _payload['caption'],
        "description": _payload['description'],
        "image_alt": _payload['image_alt']
    }
    return doPrivatePostRequest(payload, 'v1/images/create')
}

export const updateImage = () => {
    const payload = {}
    return doPrivatePostRequest(payload, '/images/update')
}

export const uploadImage = (_doc_id, _file) => {
    let payload = new FormData();
    payload.append('file', _file);
    return doPrivateFilePostRequest(payload, `images/upload/${_doc_id}`)
}

export const getImage = (_doc_id) => {
    return doPrivateGetRequest(`images/get/${_doc_id}`)
}

export const getAllImages = () => {
    return doPrivateGetRequest('images')
}

export const deleteImage = (_doc_id) => {
    return doPrivateDelRequest(`images/delete/${_doc_id}`)
}
