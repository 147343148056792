import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Button, Modal, Tabs, Tab } from '@themesberg/react-bootstrap';
import { getAllUsers } from '../../api/private/users';
import { getAllSuppliers } from '../../api/private/suppliers';
import Papa from 'papaparse';
import Select from 'react-select';
import { createProduct } from '../../api/private/products';
import AlertComponent from '../../functions/alerts';

const AddBrandModal = ({ users, showModal, handleCloseModal, createBrands, createOffering, updateBrands, initialBrandData, isEditMode }) => {
    const [brandsData, setBrandData] = useState({
        _id: '',
        brand_name: '',
        brand_image: '',
        brand_location: '',
        brand_description: '',
        linked_user: [{ doc_id: '', email: '' }],
        founded: '',
        meta: [{
            address: '',
            contact_number: '',
            bbeee_level: '',
            bbeee_validity: '',
            website: '',
            brand_logo: ''
        }]
    });
    const [uploadCsvFile, setUploadCsvFile] = useState(null);
    const [companyUsers, setCompanyUsers] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [selectedLocationOptions, setSelectedLocationOptions] = useState([]);
    const [alert, setAlert] = useState({ type: '', message: '' });

    useEffect(() => {
        loadUsers();
        loadSuppliers();
        if (showModal && isEditMode && initialBrandData) {
            const updatedData = {
                _id: initialBrandData['_id'] || '',
                brand_name: initialBrandData['brand_name'] || '',
                brand_image: initialBrandData['brand_image'] || '',
                brand_location: initialBrandData['brand_location'] || '',
                brand_description: initialBrandData['brand_description'] || '',
                linked_user: Array.isArray(initialBrandData.linked_user) ? [...initialBrandData.linked_user] : [{ doc_id: '', email: '' }],
                meta: Array.isArray(initialBrandData.meta) ? [...initialBrandData.meta] : [{
                    address: '',
                    contact_number: '',
                    bbeee_level: '',
                    bbeee_validity: '',
                    website: '',
                    brand_logo: ''
                }]
            };

            setSelectedLocationOptions(updatedData.brand_location.split(',').map(location => ({ value: location, label: location })));
            setBrandData(updatedData);
        } else {
            setBrandData({
                _id: '',
                brand_name: '',
                brand_image: '',
                brand_location: '',
                brand_description: '',
                linked_user: [{ doc_id: '', email: '' }],
                founded: '',
                meta: [{
                    address: '',
                    contact_number: '',
                    bbeee_level: '',
                    bbeee_validity: '',
                    website: '',
                    brand_logo: ''
                }]
            });
            setSelectedLocationOptions([]);
        }
    }, [initialBrandData, isEditMode, showModal]);

    const loadUsers = () => {
        getAllUsers().then(response => {
            if (response.data.Result === 'OK') {
                const records = response.data.Records;
                setCompanyUsers(records.map(user => ({ value: user._id, label: user.email_address })));
            } else {
                console.warn(response.data);
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const loadSuppliers = () => {
        getAllSuppliers().then(response => {
            if (response.data.Result === 'OK') {
                const records = response.data.Records;
                setSuppliers(records);
            } else {
                console.warn(response.data);
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleCsvFileChange = (event) => {
        setUploadCsvFile(event.target.files[0]);
    };

    const handleCsvUpload = () => {
        if (uploadCsvFile) {
            Papa.parse(uploadCsvFile, {
                complete: function (results) {
                    if (results.errors.length) {
                        setAlert({ type: 'warning', message: `Parsing errors: ${results.errors}` });
                        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
                    } else {
                        if (results.data.length === 0) {
                            setAlert({ type: 'error', message: 'No data found in the CSV file.' });
                            setTimeout(() => setAlert({ type: '', message: '' }), 5000);
                        } else {
                            results.data.forEach((row, index) => {
                                const productData = {
                                    brand: row["Brand"],
                                    name: row["Product Name"],
                                    tags: row["Tags"],
                                    series_name: row["Series Name"],
                                    model_numbers: row["Model Numbers"],
                                    load_capacity: row["Load Capacity"],
                                    load_centre: row["Load Centre"],
                                    lift_height: row["Lift Height"],
                                    extended_mast_height: row["Extended Mast Height"],
                                    special_features: row["Special Features"],
                                    description: row["Description"],
                                    product_images: row["Product Images"]
                                };
                                if (Object.values(productData).some(value => value === undefined || value === null || value === '')) {
                                    setAlert({ type: 'warning', message: `Row ${index + 1} contains incomplete values.` });
                                    setTimeout(() => setAlert({ type: '', message: '' }), 5000);
                                } else {
                                    createProduct(productData).then(response => {
                                        setAlert({ type: 'success', message: 'Product created successfully' });
                                        setTimeout(() => setAlert({ type: '', message: '' }), 5000);
                                    }).catch(error => {
                                        console.error('Error creating product:', error);
                                    });
                                }
                            });
                        }
                    }
                },
                header: true,
                skipEmptyLines: true,
            });
        } else {
            alert('Please select a CSV file first!');
        }
    };

    const handleDownloadTemplate = () => {
        const headers = [
            "Brand", "Product Name", "Tags", "Series Name", "Model Numbers",
            "Load Capacity", "Load Centre", "Lift Height", "Extended Mast Height",
            "Special Features", "Description", "Product Images"
        ];

        const csvHeader = headers.join(',');
        const csvRows = [csvHeader];
        const csvString = csvRows.join("\n");
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'product_template.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const onKeyValueChange = (key, value, index = 0, field = '') => {
        setBrandData(prev => {
            const newState = { ...prev };

            if (field === 'meta') {
                newState.meta = prev.meta.map((item, idx) => idx === index ? { ...item, [key]: value } : item);
            } else {
                newState[key] = value;
            }
            return newState;
        });
    };

    const handleLinkedUserChange = (selected) => {
        setBrandData(prev => ({
            ...prev,
            linked_user: (selected || []).map(user => ({ doc_id: user.value, email: user.label }))
        }));
    };

    const handleFileChange = (key, files, isMeta = false) => {
        if (files.length > 0) {
            if (isMeta) {
                setBrandData(prev => ({
                    ...prev,
                    meta: [{ ...prev.meta[0], [key]: files[0] }]
                }));
            } else {
                setBrandData(prev => ({
                    ...prev,
                    [key]: files[0],
                }));
            }
        }
    };

    const handleSelectChange = (selected) => {
        setSelectedLocationOptions(selected);
    };

    const handleSubmit = () => {
        const payload = {
            ...brandsData,
            brand_location: selectedLocationOptions.map(option => option.value).join(',')
        };

        if (isEditMode) {
            updateBrands(initialBrandData._id, payload);
        } else {
            createBrands(payload);
        }

        handleCloseModal();
    };

    const provinces = [
        { value: 'Eastern Cape', label: 'Eastern Cape' },
        { value: 'Free State', label: 'Free State' },
        { value: 'Gauteng', label: 'Gauteng' },
        { value: 'KwaZulu-Natal', label: 'KwaZulu-Natal' },
        { value: 'Limpopo', label: 'Limpopo' },
        { value: 'Mpumalanga', label: 'Mpumalanga' },
        { value: 'Northern Cape', label: 'Northern Cape' },
        { value: 'North West', label: 'North West' },
        { value: 'Western Cape', label: 'Western Cape' }
    ];

    return (
        <Modal size="lg" show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>{isEditMode ? 'Update Brand' : 'Add Brand'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey="basicDetails" id="brandDetailsTabs">
                    <Tab eventKey="basicDetails" title="Basic Details">
                        <Form>
                            <Row className="pt-4 mb-3">
                                <Col md={12}>
                                    <Form.Group controlId="brand_name">
                                        <Form.Label>Brand Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter brand name"
                                            value={brandsData.brand_name}
                                            onChange={(e) => onKeyValueChange('brand_name', e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={12}>
                                    <Form.Group controlId="brand_description">
                                        <Form.Label>Brand Description</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Enter brand description"
                                            value={brandsData.brand_description}
                                            onChange={(e) => onKeyValueChange('brand_description', e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="brand_location">
                                        <Form.Label>Brand Location</Form.Label>
                                        <Select
                                            isMulti
                                            options={provinces}
                                            value={selectedLocationOptions}
                                            onChange={handleSelectChange}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="linked_user">
                                        <Form.Label>Linked User</Form.Label>
                                        <Select
                                            isMulti
                                            options={companyUsers}
                                            value={companyUsers.filter(option => brandsData.linked_user.some(user => user.doc_id === option.value))}
                                            onChange={handleLinkedUserChange}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3 mt-3 pt-4">
                                <Col md={2}>
                                    <img src={brandsData.brand_image} height="100px" width="100px" alt="Brand" />
                                </Col>
                                <Col md={10}>
                                    <Form.Group controlId="brand_logo">
                                        <Form.Label>Brand Logo</Form.Label>
                                        <Form.Control type="file" onChange={(e) => handleFileChange('brand_logo', e.target.files, true)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col md={2}>
                                    <img src={brandsData.brand_image} height="100px" width="100px" alt="Brand" />
                                </Col>
                                <Col md={10}>
                                    <Form.Group controlId="brand_image">
                                        <Form.Label>Brand Banner</Form.Label>
                                        <Form.Control type="file" onChange={(e) => handleFileChange('brand_image', e.target.files)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </Tab>
                    <Tab eventKey="products" title="Products">
                        <Form>
                            <Row>
                                <Col md={12}>
                                    <div className="csv-upload mx-3 my-3">
                                        <Form.Label>Download our CSV template and then upload your product CSV</Form.Label>
                                        <Button variant="primary" onClick={handleDownloadTemplate} className="mx-4 template-btn">Download Template</Button>
                                        <Form.Group>
                                            <Form.Label>Upload Products CSV</Form.Label>
                                            <Form.Control type="file" accept=".csv" onChange={handleCsvFileChange} />
                                        </Form.Group>
                                        <AlertComponent className="my-3" type={alert.type} message={alert.message} />
                                        <Button className="mt-4" variant="primary" onClick={handleCsvUpload}>Upload Product List</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                <Button variant="brand-orange" onClick={handleSubmit}>
                    {isEditMode ? 'Update Brands' : 'Add Brands'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddBrandModal;
