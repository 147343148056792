// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* DashboardOverview.css */
.dashboard-container {
    padding: 20px;
    font-family: 'Roboto', sans-serif;
  }
  
  .dashboard-title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .dash-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 2px 4px 2px 4px rgba(0, 0, 0, 0.01);
    margin-bottom: 20px;
    background-color: #fff;
  }
  
   .card-title {
    font-size: 1.2rem;
    margin-bottom: 8px;
    color: #555;
  }
  
  .card-value {
    font-size: 2rem;
    color: #000;
  }
  
  .grid-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .grid-item {
    flex: 1 1 200px;
  }
  
  .chart-container {
    flex: 1 1 500px; /* Ensure charts are wider */
    margin-top: 20px;
  }  `, "",{"version":3,"sources":["webpack://./src/pages/admin/dashboard/DashboardOverview.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;IACI,aAAa;IACb,iCAAiC;EACnC;;EAEA;IACE,eAAe;IACf,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,sBAAsB;IACtB,kBAAkB;IAClB,aAAa;IACb,+CAA+C;IAC/C,mBAAmB;IACnB,sBAAsB;EACxB;;GAEC;IACC,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,eAAe;IACf,WAAW;EACb;;EAEA;IACE,aAAa;IACb,eAAe;IACf,SAAS;EACX;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe,EAAE,4BAA4B;IAC7C,gBAAgB;EAClB","sourcesContent":["/* DashboardOverview.css */\n.dashboard-container {\n    padding: 20px;\n    font-family: 'Roboto', sans-serif;\n  }\n  \n  .dashboard-title {\n    font-size: 2rem;\n    margin-bottom: 20px;\n    color: #333;\n  }\n  \n  .dash-card {\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    padding: 16px;\n    box-shadow: 2px 4px 2px 4px rgba(0, 0, 0, 0.01);\n    margin-bottom: 20px;\n    background-color: #fff;\n  }\n  \n   .card-title {\n    font-size: 1.2rem;\n    margin-bottom: 8px;\n    color: #555;\n  }\n  \n  .card-value {\n    font-size: 2rem;\n    color: #000;\n  }\n  \n  .grid-container {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 16px;\n  }\n  \n  .grid-item {\n    flex: 1 1 200px;\n  }\n  \n  .chart-container {\n    flex: 1 1 500px; /* Ensure charts are wider */\n    margin-top: 20px;\n  }  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
