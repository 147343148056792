import React from 'react'
import { Carousel, Accordion, Col, Row, Form, Modal, Card, Button, Container, InputGroup, ToggleButton, ButtonGroup, Table } from '@themesberg/react-bootstrap';
import { faAngleLeft, faQuestionCircle, faIdCard, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PersonalDetailsForm = ({ personalDetails, setPersonalDetails }) => (
    <Row>
      <Col md={12}>
        <Form.Group id="account" className="mb-4">
          <Form.Label>Account Type</Form.Label>
          <InputGroup>
            <Form.Control
              as="select"
              required
              value={personalDetails.accountType}
              onChange={e => {
                const selectedAccountType = e.target.value;
                let role = "";
  
                if (selectedAccountType === "buyer") {
                  role = "buyer";
                } else if (selectedAccountType === "seller") {
                  role = "seller";
                }
  
                setPersonalDetails({
                  ...personalDetails,
                  accountType: selectedAccountType,
                  role: role
                });
              }}
            >
              <option value="">Select Account Type</option>
              <option value="buyer">Buyer</option>
              <option value="seller">Seller</option>
            </Form.Control>
          </InputGroup>
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group id="first_name" className="mb-4">
          <Form.Label>Your First Name</Form.Label>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faIdCard} />
            </InputGroup.Text>
            <Form.Control
              autoFocus
              name="first_name"
              required
              type="text"
              placeholder="John"
              value={personalDetails.firstName}
              onChange={e => setPersonalDetails({ ...personalDetails, firstName: e.target.value })}
            />
          </InputGroup>
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group id="last_name" className="mb-4">
          <Form.Label>Your Last Name</Form.Label>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faIdCard} />
            </InputGroup.Text>
            <Form.Control
              required
              name="last_name"
              type="text"
              placeholder="Doe"
              value={personalDetails.lastName}
              onChange={e => setPersonalDetails({ ...personalDetails, lastName: e.target.value })}
            />
          </InputGroup>
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group id="email_address" className="mb-4">
          <Form.Label>Your Email</Form.Label>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faEnvelope} />
            </InputGroup.Text>
            <Form.Control
              autoFocus
              name="email"
              required
              type="email"
              placeholder="example@company.com"
              value={personalDetails.email}
              onChange={e => setPersonalDetails({ ...personalDetails, email: e.target.value })}
            />
          </InputGroup>
        </Form.Group>
      </Col>
      <Col md={6}>
        <Form.Group id="password" className="mb-4">
          <Form.Label>Your Password</Form.Label>
          <InputGroup>
            <InputGroup.Text>
              <FontAwesomeIcon icon={faUnlockAlt} />
            </InputGroup.Text>
            <Form.Control
              required
              type="password"
              name="password"
              placeholder="Password"
              value={personalDetails.password}
              onChange={e => setPersonalDetails({ ...personalDetails, password: e.target.value })}
            />
          </InputGroup>
        </Form.Group>
      </Col>
    </Row >
  );

  export default PersonalDetailsForm;