import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest } from "./requests";

export const createFaqs = (title, type) => {

    const payload = {
        "title": title,
        "type": type
    }

    return doPrivatePostRequest(payload, 'faqs/create')
}

export const updateFaqs = (_doc_id, payload) => {
    return doPrivatePostRequest(payload, `faqs/update/${_doc_id}`)
}

export const getBuyers = (_doc_id) => {
    return doPrivateGetRequest(`faqs/get/${_doc_id}`)
}

export const getAllFaqs = () => {
    return doPrivateGetRequest('faqs')
}

export const deleteBuyers = (_doc_id) => {
    return doPrivateDelRequest(`faqs/delete/${_doc_id}`)
}
