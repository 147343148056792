import React from 'react';
import { Accordion, Row, Col, Form, InputGroup } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import '../scss/styles.css';

const CompanyDetailsForm = ({ companyDetails, setCompanyDetails }) => {
  // Function to check if all fields in a section are completed
  const isSectionComplete = (fields) => fields.every((field) => field !== undefined && field.trim() !== '');

  // Define field groups for each section
  const sections = {
    general: [
      companyDetails.legal_name,
      companyDetails.trading_name,
      companyDetails.registration_number,
      companyDetails.vat_number,
    ],
    primaryContact: [
      companyDetails.primary_name,
      companyDetails.primary_surname,
      companyDetails.primary_designation,
      companyDetails.contact_number,
      companyDetails.primary_email,
    ],
    addresses: [companyDetails.registered_address, companyDetails.billing_address],
    socials: [
      companyDetails.social_links?.linkedin,
      companyDetails.social_links?.facebook,
      companyDetails.social_links?.instagram,
      companyDetails.social_links?.twitter,
    ],
  };

  const designations = [
    "Executive leadership (CEO, CTO, CMO, CFO)",
    "Operations and management (Operations Manager, Team Lead / Supervisors)",
    "Sales and business development",
    "Marketing and communications",
    "Procurement Manager",
    "Customer service and support",
    "Administration"
  ];

  return (
    <Accordion defaultActiveKey="0" className="company-accordion">
      {/* General Company Details */}
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <div className="accordion-header-content">
            General Company Details
            <FontAwesomeIcon
              icon={isSectionComplete(sections.general) ? faCheckCircle : faExclamationTriangle}
              className={isSectionComplete(sections.general) ? 'text-success' : 'text-warning'}
            />
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <Row>
            <Col md={6}>
              <Form.Group id="legal_name" className="mb-4">
                <Form.Label>Company Legal Name</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    name="legal_name"
                    type="text"
                    placeholder="Company Legal Name"
                    value={companyDetails.legal_name}
                    onChange={(e) => setCompanyDetails({ ...companyDetails, legal_name: e.target.value })}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group id="trading_name" className="mb-4">
                <Form.Label>Trading Name</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    name="trading_name"
                    type="text"
                    placeholder="Trading Name"
                    value={companyDetails.trading_name}
                    onChange={(e) => setCompanyDetails({ ...companyDetails, trading_name: e.target.value })}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group id="reg_number" className="mb-4">
                <Form.Label>Registration Number</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    name="reg_number"
                    type="text"
                    placeholder="Registration Number"
                    value={companyDetails.registration_number}
                    onChange={(e) => setCompanyDetails({ ...companyDetails, registration_number: e.target.value })}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group id="vat_number" className="mb-4">
                <Form.Label>VAT Number</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    name="vat_number"
                    type="text"
                    placeholder="VAT Number"
                    value={companyDetails.vat_number}
                    onChange={(e) => setCompanyDetails({ ...companyDetails, vat_number: e.target.value })}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>

      {/* Primary Contact */}
      <Accordion.Item eventKey="1">
        <Accordion.Header>
          <div className="accordion-header-content">
            Primary Contact
            <FontAwesomeIcon
              icon={isSectionComplete(sections.primaryContact) ? faCheckCircle : faExclamationTriangle}
              className={isSectionComplete(sections.primaryContact) ? 'text-success' : 'text-warning'}
            />
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <Row>
            <Col md={6}>
              <Form.Group id="primary_name" className="mb-4">
                <Form.Label>First Name</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    name="primary_name"
                    type="text"
                    placeholder="First Name"
                    value={companyDetails.primary_name}
                    onChange={(e) => setCompanyDetails({ ...companyDetails, primary_name: e.target.value })}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group id="primary_surname" className="mb-4">
                <Form.Label>Last Name</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    type="text"
                    name="primary_surname"
                    placeholder="Last Name"
                    value={companyDetails.primary_surname}
                    onChange={(e) => setCompanyDetails({ ...companyDetails, primary_surname: e.target.value })}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group id="primary_designation" className="mb-4">
                <Form.Label>Designation</Form.Label>
                <InputGroup>
                  <Form.Select
                    required
                    name="primary_designation"
                    value={companyDetails.primary_designation}
                    onChange={(e) => setCompanyDetails({ ...companyDetails, primary_designation: e.target.value })}
                  >
                    <option value="" disabled>Select Designation</option>
                    {designations.map((designation, index) => (
                      <option key={index} value={designation}>{designation}</option>
                    ))}
                  </Form.Select>
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group id="contact_number" className="mb-4">
                <Form.Label>Contact Number</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    type="text"
                    name="contact_number"
                    placeholder="Primary Contact Number"
                    value={companyDetails.contact_number}
                    onChange={(e) => setCompanyDetails({ ...companyDetails, contact_number: e.target.value })}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group id="primary_email" className="mb-4">
                <Form.Label>Email</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    type="email"
                    name="primary_email"
                    placeholder="Email"
                    value={companyDetails.primary_email}
                    onChange={(e) => setCompanyDetails({ ...companyDetails, primary_email: e.target.value })}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>

      {/* Addresses */}
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          <div className="accordion-header-content">
            Addresses
            <FontAwesomeIcon
              icon={isSectionComplete(sections.addresses) ? faCheckCircle : faExclamationTriangle}
              className={isSectionComplete(sections.addresses) ? 'text-success' : 'text-warning'}
            />
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <Row>
            <Col md={6} style={{ padding: '1rem' }}>
              <Form.Group id="registered_address" className="mb-4">
                <Form.Label>Registered / Physical Address</Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    name="registered_address"
                    type="text"
                    placeholder="unit number, building number"
                    value={companyDetails.registered_address}
                    onChange={(e) => setCompanyDetails({ ...companyDetails, registered_address: e.target.value })}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group id="registered_street" className="mb-4">
                <InputGroup>
                  <Form.Control
                    name="registered_address"
                    required
                    type="text"
                    placeholder="Street, Suburb, City"
                    value={companyDetails.registered_street}
                    onChange={(e) => setCompanyDetails({ ...companyDetails, registered_street: e.target.value })}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group id="billing_postal" className="mb-4">
                <InputGroup>
                  <Form.Control
                    name="billing_postal"
                    required
                    type="text"
                    placeholder="Postal Code"
                    value={companyDetails.billing_postal}
                    onChange={(e) => setCompanyDetails({ ...companyDetails, billing_postal: e.target.value })}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col md={6} style={{ background: '#f3f3f3', padding: '1rem' }}>
              <Form.Group id="po_box" className="mb-4">
                <Form.Label>PO Box / Billing Address (Optional)</Form.Label>
                <InputGroup>
                  <Form.Control
                    name="po_box"
                    required
                    type="text"
                    placeholder="PO Box number"
                    value={companyDetails.po_box}
                    onChange={(e) => setCompanyDetails({ ...companyDetails, po_box: e.target.value })}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group id="billing_address" className="mb-4">
                <InputGroup>
                  <Form.Control
                    name="billing_address"
                    required
                    type="text"
                    placeholder="Street, Suburb, City"
                    value={companyDetails.billing_address}
                    onChange={(e) => setCompanyDetails({ ...companyDetails, billing_address: e.target.value })}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group id="postal_code" className="mb-4">
                <InputGroup>
                  <Form.Control
                    name="postal_code"
                    required
                    type="text"
                    placeholder="Postal Code"
                    value={companyDetails.postal_code}
                    onChange={(e) => setCompanyDetails({ ...companyDetails, postal_code: e.target.value })}
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>

      {/* Social Links */}
      <Accordion.Item eventKey="3">
        <Accordion.Header>
          <div className="accordion-header-content">
            Social Links
            <FontAwesomeIcon
              icon={isSectionComplete(sections.socials) ? faCheckCircle : faExclamationTriangle}
              className={isSectionComplete(sections.socials) ? 'text-success' : 'text-warning'}
            />
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <Row>
            <Col md={6}>
              <Form.Group id="linkedin" className="mb-4">
                <Form.Label>LinkedIn</Form.Label>
                <InputGroup>
                  <InputGroup.Text>https://</InputGroup.Text>
                  <Form.Control
                    type="text" // Use text instead of url to prevent validation issues
                    name="linkedin"
                    placeholder="linkedin.com/in/your-profile"
                    value={companyDetails.social_links?.linkedin || ''}
                    onChange={(e) =>
                      setCompanyDetails({
                        ...companyDetails,
                        social_links: {
                          ...companyDetails.social_links,
                          linkedin: e.target.value.replace(/^https?:\/\//, '') // Strip https:// from input
                        }
                      })
                    }
                    required
                  />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group id="facebook" className="mb-4">
                <Form.Label>Facebook</Form.Label>
                <InputGroup>
                  <InputGroup.Text>https://</InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="facebook"
                    placeholder="facebook.com/your-page"
                    value={companyDetails.social_links?.facebook || ''}
                    onChange={(e) =>
                      setCompanyDetails({
                        ...companyDetails,
                        social_links: {
                          ...companyDetails.social_links,
                          facebook: e.target.value.replace(/^https?:\/\//, '')
                        }
                      })
                    }
                    required
                  />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group id="instagram" className="mb-4">
                <Form.Label>Instagram</Form.Label>
                <InputGroup>
                  <InputGroup.Text>https://</InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="instagram"
                    placeholder="instagram.com/your-handle"
                    value={companyDetails.social_links?.instagram || ''}
                    onChange={(e) =>
                      setCompanyDetails({
                        ...companyDetails,
                        social_links: {
                          ...companyDetails.social_links,
                          instagram: e.target.value.replace(/^https?:\/\//, '')
                        }
                      })
                    }
                    required
                  />
                </InputGroup>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group id="twitter" className="mb-4">
                <Form.Label>Twitter</Form.Label>
                <InputGroup>
                  <InputGroup.Text>https://</InputGroup.Text>
                  <Form.Control
                    type="text"
                    name="twitter"
                    placeholder="twitter.com/your-handle"
                    value={companyDetails.social_links?.twitter || ''}
                    onChange={(e) =>
                      setCompanyDetails({
                        ...companyDetails,
                        social_links: {
                          ...companyDetails.social_links,
                          twitter: e.target.value.replace(/^https?:\/\//, '')
                        }
                      })
                    }
                    required
                  />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default CompanyDetailsForm;
