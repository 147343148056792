import React from 'react';
import { Carousel, Col, Row, Form, Card, Button, ButtonGroup, ToggleButton } from '@themesberg/react-bootstrap';

const PackageSelectionForm = ({ packages, selectedPackage, setSelectedPackage, isAnnual, setIsAnnual }) => {
    // Helper function to chunk packages into groups of 3
    const chunkPackages = (arr, chunkSize) => {
        let result = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            result.push(arr.slice(i, i + chunkSize));
        }
        return result;
    };

    // Ensure package selection reflects correct pricing mode
    const filteredPackages = packages.filter(pkg => isAnnual ? pkg.annual_price : pkg.monthly_price);

    // Chunk the filtered packages into groups of 3
    const chunkedPackages = chunkPackages(filteredPackages, 3);

    return (
        <Row>
            <Col md={12} className="text-center mb-4">
                <ButtonGroup>
                    {/* Toggle button for Monthly Plans */}
                    <ToggleButton
                        type="radio"
                        variant="outline-primary"
                        name="radio"
                        value={false}
                        checked={!isAnnual}
                        onChange={() => setIsAnnual(false)} // Set to false for monthly prices
                    >
                        Monthly Plans
                    </ToggleButton>

                    {/* Toggle button for Annual Plans */}
                    <ToggleButton
                        type="radio"
                        variant="outline-primary"
                        name="radio"
                        value={true}
                        checked={isAnnual}
                        onChange={() => setIsAnnual(true)} // Set to true for annual prices
                    >
                        Annual Plans
                    </ToggleButton>
                </ButtonGroup>
            </Col>

            <Col md={12}>
                <Form.Group id="package_selection" className="mb-4">
                    <Form.Label>Select a Package</Form.Label>
                    {chunkedPackages.length > 0 ? (
                        <Carousel interval={null} indicators={true} controls={true}>
                            {chunkedPackages.map((packageChunk, chunkIndex) => (
                                <Carousel.Item key={chunkIndex}>
                                    <Row className="justify-content-center">
                                        {packageChunk.map((pkg) => (
                                            <Col key={pkg._id} md={4}>
                                                <Card className={`p-3 package-card text-center ${selectedPackage === pkg._id ? 'border-primary' : ''}`}>
                                                    <Card.Body>
                                                        <Card.Title>{pkg.title}</Card.Title>
                                                        <Card.Text>
                                                            <strong>{isAnnual ? "Annual Price" : "Monthly Price"}: </strong>
                                                            R{isAnnual ? pkg.annual_price : pkg.monthly_price}
                                                            <br />
                                                            <strong>Features:</strong>
                                                            <ul className="text-left">
                                                                {pkg.features.map((feature, idx) => (
                                                                    <li key={idx}>{feature.text}</li>
                                                                ))}
                                                            </ul>
                                                        </Card.Text>
                                                        <Button
                                                            variant={selectedPackage === pkg._id ? 'primary' : 'outline-primary'}
                                                            onClick={() => setSelectedPackage(pkg._id)}
                                                        >
                                                            {selectedPackage === pkg._id ? 'Selected' : 'Select'}
                                                        </Button>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    ) : (
                        <p>No packages available at the moment.</p>
                    )}
                </Form.Group>
            </Col>
        </Row>
    );
};

export default PackageSelectionForm;
