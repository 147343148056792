import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest } from "./requests";

export const createUser = (first_name, last_name, email) => {

    const payload = {
            'first_name': first_name,
            'last_name': last_name,
            'email': email
        }

    return doPrivatePostRequest(payload, 'users/create')
}

export const updateUser = (_doc_id, payload) => {
    return doPrivatePostRequest(payload, `users/update/${_doc_id}`)
} 

export const registerUser = (_first_name, _last_name, _email, _mobile_number, _password) => {
    const payload = {
            'first_name': _first_name,
            'last_name': _last_name,
            'email': _email,
            'mobile_number': _mobile_number,
            'password': _password
        }
    return doPrivatePostRequest(payload, 'auth/register')
}

export const getUser = (_doc_id) => {
    return doPrivateGetRequest(`users/get/${_doc_id}`)
}

export const getAllUsers = () => {
    return doPrivateGetRequest('users')
}

export const deleteUsers = (_doc_id) => {
    return doPrivateDelRequest(`users/delete/${_doc_id}`)
}
