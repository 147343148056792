import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest } from "./requests";

// Create an offering
export const createOfferings = (offeringData) => {
    return doPrivatePostRequest(offeringData, 'offerings');
}

// Update an offering by ID
export const updateOfferings = (offeringId, offeringData) => {
    return doPrivatePostRequest(offeringData, `offerings/${offeringId}`);
}

// Get a specific offering by ID
export const getOfferings = (offeringId) => {
    return doPrivateGetRequest(`offerings/${offeringId}`);
}

// Get all offerings
export const getAllOfferings = () => {
    return doPrivateGetRequest('offerings');
}

// Delete an offering by ID
export const deleteOfferings = (offeringId) => {
    return doPrivateDelRequest(`offerings/${offeringId}`);
}
