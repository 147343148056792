import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Card, Container, Button, Row, Col, Table } from '@themesberg/react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const SignupSuccess = () => {
    const history = useHistory();
    const [personalDetails, setPersonalDetails] = useState({});
    const [companyDetails, setCompanyDetails] = useState({});
    const [selectedPackage, setSelectedPackage] = useState({});
    const [paymentDetails, setPaymentDetails] = useState({});
    const [debitOrderDetails, setDebitOrderDetails] = useState({});
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [addonsSummary, setAddonsSummary] = useState([]);
    const [isAnnual, setIsAnnual] = useState(false);

    useEffect(() => {
        const state = history.location.state || JSON.parse(localStorage.getItem('signupData'));
        console.log("SignupSuccess received state:", state);
        if (state) {
            setPersonalDetails(state.personalDetails || {});
            setCompanyDetails(state.companyDetails || {});
            setSelectedPackage(state.selectedPackage || {});
            setPaymentDetails(state.paymentDetails || {});
            setDebitOrderDetails(state.debitOrderDetails || {});
            setAgreedToTerms(state.agreedToTerms || false);
            setAddonsSummary(state.addonsSummary || []);
            setIsAnnual(state.isAnnual || false);
        }
    }, [history]);

    const transactionId = paymentDetails?.transactionId || 'Pending';
    const amountPaid = parseFloat(paymentDetails?.amount || 0);
    const paymentDate = paymentDetails?.date || 'N/A';
    const packageName = selectedPackage?.title || 'N/A';
    const packagePrice = parseFloat(isAnnual ? selectedPackage?.annual_price || 0 : selectedPackage?.monthly_price || 0);

    const handleDownloadReceipt = () => {
        const doc = new jsPDF();

        // Text Details
        doc.text("Invoice", 14, 20);
        doc.text(`Date: ${new Date().toLocaleDateString()}`, 14, 30);
        doc.text(`Package: ${packageName}`, 14, 40);
        doc.text(`Billing Address: ${companyDetails.billing_address || 'N/A'}`, 14, 50);

        // Table for Package and Add-ons
        doc.autoTable({
            startY: 60,
            head: [["Item", "Description", "Quantity", "Price (ZAR)", "Total (ZAR)"]],
            body: [
                ["Package", packageName, "1", packagePrice.toFixed(2), packagePrice.toFixed(2)],
                ...addonsSummary.map(addon => [
                    addon.name,
                    "Addon",
                    addon.quantity,
                    addon.unitPrice?.toFixed(2) || '0.00',
                    addon.totalPrice?.toFixed(2) || '0.00',
                ]),
            ],
        });

        // Summary of Totals
        const addonTotal = addonsSummary.reduce((total, addon) => total + (addon.totalPrice || 0), 0);
        const vatAmount = (packagePrice + addonTotal) * 0.15;
        const totalInclVAT = packagePrice + addonTotal + vatAmount;

        doc.autoTable({
            startY: doc.autoTable.previous.finalY + 10,
            head: [["Description", "Amount (ZAR)"]],
            body: [
                ["Subtotal (Excl. VAT)", (packagePrice + addonTotal).toFixed(2)],
                ["VAT (15%)", vatAmount.toFixed(2)],
                ["Total (Incl. VAT)", totalInclVAT.toFixed(2)],
            ],
        });

        doc.save(`receipt_${transactionId || 'Pending'}.pdf`);
    };

    const handleSignIn = () => {
        history.push('/login');
    };

    return (
        <Container fluid className="min-vh-100 d-flex flex-column bg-white text-dark py-6 justify-content-center align-items-center">
            <Row className="text-center mb-4">
                <Col>
                    <FontAwesomeIcon icon={faCheckCircle} size="4x" className="text-success" />
                    <h3 className="d-inline-block ms-3">Signup Success</h3>
                </Col>
            </Row>
            <Row className="text-center">
                <Col>
                    <h5 className="mb-3">Thank you for signing up! You can now access your dashboard or visit the main site.</h5>
                </Col>
            </Row>

            {/* Buttons */}
            <Row className="text-center mb-4">
                <Col>
                    <Button variant="primary" className="me-2" onClick={() => history.push('/dashboard/overview')}>
                        Go To Dashboard
                    </Button>
                    <Button variant="secondary" onClick={() => window.location.href = '/'}>
                        Go To Site
                    </Button>
                </Col>
            </Row>
            <Card className="p-4 text-black" style={{ width: '80%', maxWidth: '900px' }}>
                <Card.Body>
                    {/* Invoice Header */}
                    <div className="text-center mb-4">
                        <h3>Invoice</h3>
                        <p>Invoice Date: {new Date().toLocaleDateString()}</p>
                        <p>Invoice Number: {transactionId || 'Pending'}</p>
                    </div>

                    {/* Recipient and Company Details */}
                    <Row>
                        <Col md={6}>
                            <h5>Bill To:</h5>
                            <p>
                                {personalDetails?.firstName || 'N/A'} {personalDetails?.lastName || 'N/A'} <br />
                                Email: {personalDetails?.email || 'N/A'} <br />
                                Account Type: {personalDetails?.accountType || 'N/A'}
                            </p>
                        </Col>
                        <Col md={6}>
                            <h5>Company Details:</h5>
                            <p>
                                Legal Name: {companyDetails?.legal_name || 'N/A'} <br />
                                Trading Name: {companyDetails?.trading_name || 'N/A'} <br />
                                Registration Number: {companyDetails?.registration_number || 'N/A'} <br />
                                VAT Number: {companyDetails?.vat_number || 'N/A'}
                            </p>
                        </Col>
                    </Row>

                    {/* Package and Add-ons Details */}
                    <Table striped bordered className="mt-4">
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Description</th>
                                <th>Quantity</th>
                                <th>Unit Price</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Package Details */}
                            <tr>
                                <td>Package</td>
                                <td>{packageName}</td>
                                <td>1</td>
                                <td>R{packagePrice.toFixed(2)}</td>
                                <td>R{packagePrice.toFixed(2)}</td>
                            </tr>
                            {/* Add-ons */}
                            {addonsSummary.length > 0 ? (
                                addonsSummary.map((addon, index) => (
                                    <tr key={index}>
                                        <td>{addon.name}</td>
                                        <td>Addon</td>
                                        <td>{addon.quantity}</td>
                                        <td>R{addon.unitPrice?.toFixed(2) || '0.00'}</td>
                                        <td>R{addon.totalPrice?.toFixed(2) || '0.00'}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={5} className="text-center">No add-ons selected</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                    {/* Payment Summary */}
                    <Table striped bordered className="mt-4">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Subtotal (Excl. VAT)</td>
                                <td>R{(packagePrice + addonsSummary.reduce((total, addon) => total + (addon.totalPrice || 0), 0)).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>VAT (15%)</td>
                                <td>R{((packagePrice + addonsSummary.reduce((total, addon) => total + (addon.totalPrice || 0), 0)) * 0.15).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>Total (Incl. VAT)</td>
                                <td>R{(packagePrice + addonsSummary.reduce((total, addon) => total + (addon.totalPrice || 0), 0) + (packagePrice + addonsSummary.reduce((total, addon) => total + (addon.totalPrice || 0), 0)) * 0.15).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>Amount Paid</td>
                                <td>R{amountPaid.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>Payment Date</td>
                                <td>{paymentDate}</td>
                            </tr>
                        </tbody>
                    </Table>

                    <Row className="text-center mt-4">
                        <Col>
                            <Button variant="primary" onClick={handleDownloadReceipt}>
                                Download Receipt (PDF)
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default SignupSuccess;
