import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest } from "./requests";

export const createCategories = (payload) => {
    return doPrivatePostRequest(payload, 'categories/create')
}

export const updateCategories = () => {

    const payload = {}

    return doPrivatePostRequest(payload, 'categories/update')
}

export const getCategories = (_doc_id) => {
    return doPrivatePostRequest(`categories/get/${_doc_id}`)
}

export const getAllCategories = () => {
    return doPrivateGetRequest('categories')
}

export const deleteCategories = (_doc_id) => {
    return doPrivateDelRequest(`categories/delete/${_doc_id}`)
}
