import { doPrivatePostRequest, doPrivateGetRequest, doPrivateDelRequest } from "./requests";

export const createPosts = (payload) => {

    return doPrivatePostRequest(payload, 'posts/create')
}

export const updatePosts = (_doc_id, payload) => {
    return doPrivatePostRequest(payload, `posts/update/${_doc_id}`)
}

export const getPosts = (_doc_id) => {
    return doPrivatePostRequest(`posts/get/${_doc_id}`)
}

export const getAllPosts = () => {
    return doPrivateGetRequest('posts')
}

export const deletePosts = (_doc_id) => {
    return doPrivateDelRequest(`posts/delete/${_doc_id}`)
}
