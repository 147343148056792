import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Card, Table, Dropdown, ButtonGroup, Pagination, Button, Form } from '@themesberg/react-bootstrap';
import TransactionDetailsModal from "../modals/TransactionDetailsModal";
import jsPDF from "jspdf";

export const TransactionsTable = ({ transactions }) => {
  const ITEMS_PER_PAGE = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    packageName: "",
    transactionDate: "",
    paymentStatus: "",
    amount: "",
  });

  const [selectedTransaction, setSelectedTransaction] = useState(null); // State to store the selected transaction
  const [showModal, setShowModal] = useState(false);  // Modal visibility state
  const [selectedTransactions, setSelectedTransactions] = useState([]); // State to store selected transactions

  // Helper function to calculate total amount for selected transactions
  const calculateTotalAmount = () => {
    return transactions
      .filter(transaction => selectedTransactions.includes(transaction._id))
      .reduce((total, transaction) => total + parseFloat(transaction.amount || 0), 0);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const handleCheckboxChange = (transactionId) => {
    setSelectedTransactions((prevSelected) => {
      if (prevSelected.includes(transactionId)) {
        return prevSelected.filter(id => id !== transactionId);
      } else {
        return [...prevSelected, transactionId];
      }
    });
  };

  const downloadInvoice = (transactionData) => {
    const doc = new jsPDF();

    doc.setFontSize(16);
    doc.text("Invoice", 14, 20);
    doc.setFontSize(12);

    // Add transaction details
    doc.text(`Transaction ID: ${transactionData._id}`, 14, 30);
    doc.text(`Date: ${transactionData.transaction_date}`, 14, 40);
    doc.text(`Amount: $${transactionData.amount}`, 14, 50);
    doc.text(`Description: ${transactionData.package_name}`, 14, 60);

    doc.save(`invoice_${transactionData._id}.pdf`);
  };

  const handleViewClick = (transaction) => {
    console.log("Transaction Data:", transaction); // Log full transaction data

    const formattedAddons = Object.keys(transaction.addons || {}).reduce((acc, addonKey) => {
      const addonData = transaction.addons[addonKey];
      acc[addonKey] = {
        quantity: addonData?.quantity || 0,
        unit_price: addonData?.unit_price || 0,
        total_price: addonData?.total_price || 0,
      };
      return acc;
    }, {});

    console.log("Formatted Addons:", formattedAddons);

    setSelectedTransaction({
      ...transaction,
      addons: formattedAddons,
      package_price: transaction.package_price || 0, // Add package price here
    });
    setShowModal(true);  // Show the modal
  };

  const handleCloseModal = () => setShowModal(false);  // Close modal function

  const handlePayClick = () => {
    const totalAmount = calculateTotalAmount();
    console.log("Total amount for selected transactions:", totalAmount);

    if (totalAmount > 0) {
      // PayFast form data
      const payfastData = {
        merchant_id: "10035698", // Example merchant ID
        merchant_key: "cr3zvnu1omw7v", // Example merchant key
        amount: totalAmount.toFixed(2), // Total amount to be paid
        item_name: "Payment for selected transactions",
        return_url: "http://localhost:3000/payment-success",
        cancel_url: "http://localhost:3000/payment-cancel",
        notify_url: "http://localhost:3000/payment-notify"
      };

      // Create a form and submit it to PayFast
      const form = document.createElement('form');
      form.setAttribute('method', 'POST');
      form.setAttribute('action', 'https://sandbox.payfast.co.za/eng/process');
      form.style.display = 'none';

      // Append hidden input fields to the form
      Object.keys(payfastData).forEach(key => {
        const input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', key);
        input.setAttribute('value', payfastData[key]);
        form.appendChild(input);
      });

      // Append the form to the body and submit it
      document.body.appendChild(form);
      form.submit();
    } else {
      console.warn("No transactions selected or total amount is zero.");
    }
  };

  const handleDownloadInvoiceClick = () => {
    console.log("Download Invoice clicked for transactions:", selectedTransactions);
  };

  // Apply filters to transactions
  const filteredTransactions = transactions.filter(transaction => {
    return (
      (filters.packageName === "" || transaction.package_name?.toLowerCase().includes(filters.packageName.toLowerCase())) &&
      (filters.transactionDate === "" || transaction.transaction_date?.toLowerCase().includes(filters.transactionDate.toLowerCase())) &&
      (filters.paymentStatus === "" || transaction.payment_status?.toLowerCase().includes(filters.paymentStatus.toLowerCase())) &&
      (filters.amount === "" || transaction.amount?.toString().toLowerCase().includes(filters.amount.toLowerCase()))
    );
  });

  const currentTableData = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filteredTransactions.slice(startIndex, endIndex);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const pageCount = Math.ceil(filteredTransactions.length / ITEMS_PER_PAGE);
  const items = [];
  for (let number = 1; number <= pageCount; number++) {
    items.push(
      <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
        {number}
      </Pagination.Item>
    );
  }

  const TableRow = ({
    _id,
    package_name,
    is_annual,
    amount,
    total_addons_price,
    transaction_date,
    payment_status,
    payment_gateway_response
  }) => {
    const [showDropdown, setShowDropdown] = useState(false); // Track dropdown state

    const handleRowClick = () => {
      // Trigger modal to show when clicking on the row
      handleViewClick({
        _id,
        package_name,
        transaction_date,
        payment_status,
        amount,
        is_annual,
        addons: {}, // Leave it empty or fetch accordingly
        total_addons_price,
        package_price: '',
        currency: '',
        payment_gateway: '',
        payment_gateway_response
      });
    };

    const handleDropdownClick = (e) => {
      e.stopPropagation();  // Prevent event propagation so that the row click doesn't get triggered
      setShowDropdown((prevState) => !prevState);  // Toggle the dropdown visibility
    };

    const handleViewClickDropdown = (e) => {
      e.stopPropagation();  // Prevent event propagation when clicking "View"
      handleViewClick({
        _id,
        package_name,
        transaction_date,
        payment_status,
        amount,
        is_annual,
        addons: {}, // Ensure this is properly formatted or populated
        total_addons_price,
        package_price: '',
        currency: '',
        payment_gateway: '',
        payment_gateway_response
      });
    };

    return (
      <tr onClick={handleRowClick} style={{ cursor: 'pointer' }}> {/* Add onClick to row */}
        <td>
          <Form.Check
            type="checkbox"
            checked={selectedTransactions.includes(_id)}
            onChange={(e) => {
              e.stopPropagation();  // Prevent row click when checkbox is clicked
              handleCheckboxChange(_id);
            }}
          />
        </td>
        <td>{package_name || 'N/A'}</td>
        <td>{is_annual === true || is_annual === "true" ? 'Yes' : 'No'}</td>
        <td>{parseFloat(amount) || 0}</td>
        <td>{parseFloat(total_addons_price) || 0}</td>
        <td>{transaction_date || 'N/A'}</td>
        <td>{payment_status || 'N/A'}</td>
        <td>{payment_gateway_response?.transaction_id || 'N/A'}</td>
        <td onClick={(e) => e.stopPropagation()}>  {/* Prevent row click on dropdown */}
          <Dropdown as={ButtonGroup} show={showDropdown} onToggle={() => { }}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0" onClick={handleDropdownClick}>
              <FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleViewClickDropdown}>
                <FontAwesomeIcon icon={faEye} className="me-2" /> View
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    );
  };

  return (
    <>
      <Card border="light" className="shadow-sm">
        <Card.Body className="pb-0">
          <div className="d-flex justify-content-end mb-3">
            {selectedTransactions.length > 0 && (
              <>
                <Button variant="primary" className="me-2" onClick={handlePayClick}>Pay</Button>
                <Button variant="secondary" onClick={handleDownloadInvoiceClick}>Download Invoice</Button>
              </>
            )}
          </div>
          <Table responsive className="table-centered table-nowrap rounded mb-0">
            <thead className="thead-light">
              <tr>
                <th><Form.Check type="checkbox" onChange={(e) => setSelectedTransactions(e.target.checked ? filteredTransactions.map(tx => tx._id) : [])} /></th>
                <th>Package Name</th>
                <th>Annual</th>
                <th>Amount</th>
                <th>Total Add-ons Price</th>
                <th>Transaction Date</th>
                <th>Payment Status</th>
                <th>Transaction ID</th>
                <th>Action</th>
              </tr>
              <tr>
                <th></th>
                <th>
                  <Form.Control
                    type="text"
                    placeholder="Filter by Package Name"
                    name="packageName"
                    value={filters.packageName}
                    onChange={handleFilterChange}
                    className="mt-2"
                  />
                </th>
                <th></th>
                <th>
                  <Form.Control
                    type="text"
                    placeholder="Filter by Amount"
                    name="amount"
                    value={filters.amount}
                    onChange={handleFilterChange}
                    className="mt-2"
                  />
                </th>
                <th></th>
                <th>
                  <Form.Control
                    type="text"
                    placeholder="Filter by Transaction Date"
                    name="transactionDate"
                    value={filters.transactionDate}
                    onChange={handleFilterChange}
                    className="mt-2"
                  />
                </th>
                <th>
                  <Form.Control
                    type="text"
                    placeholder="Filter by Payment Status"
                    name="paymentStatus"
                    value={filters.paymentStatus}
                    onChange={handleFilterChange}
                    className="mt-2"
                  />
                </th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredTransactions.length > 0 ? (
                currentTableData().map(transaction => (
                  <TableRow key={`transaction-${transaction._id}`} {...transaction} />
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="text-center">No transactions found.</td>
                </tr>
              )}
            </tbody>
          </Table>
          <Pagination className="mt-4 justify-content-center">{items}</Pagination>
        </Card.Body>
      </Card>

      {/* Transaction Details Modal */}
      <TransactionDetailsModal
        show={showModal}
        onClose={handleCloseModal}
        transaction={selectedTransaction}
        downloadInvoice={downloadInvoice}
      />
    </>
  );
};
