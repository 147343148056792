// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Hide the desktop footer on mobile devices */
.footer {
    display: none;
  }
  
  @media (min-width: 768px) {
    .footer {
      display: block;
    }
  }
  
  /* Mobile footer styles */
  .mobile-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f8f9fa;
    border-top: 1px solid #ddd;
    z-index: 1050;
  }
  
  .mobile-footer .text-dark {
    color: #343a40 !important;
  }
  
  .mobile-footer .small {
    font-size: 12px;
  }
  
  .mobile-footer .fa-lg {
    font-size: 1.5rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/backendNav/footer.css"],"names":[],"mappings":"AAAA,8CAA8C;AAC9C;IACI,aAAa;EACf;;EAEA;IACE;MACE,cAAc;IAChB;EACF;;EAEA,yBAAyB;EACzB;IACE,eAAe;IACf,SAAS;IACT,OAAO;IACP,QAAQ;IACR,yBAAyB;IACzB,0BAA0B;IAC1B,aAAa;EACf;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":["/* Hide the desktop footer on mobile devices */\n.footer {\n    display: none;\n  }\n  \n  @media (min-width: 768px) {\n    .footer {\n      display: block;\n    }\n  }\n  \n  /* Mobile footer styles */\n  .mobile-footer {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    background-color: #f8f9fa;\n    border-top: 1px solid #ddd;\n    z-index: 1050;\n  }\n  \n  .mobile-footer .text-dark {\n    color: #343a40 !important;\n  }\n  \n  .mobile-footer .small {\n    font-size: 12px;\n  }\n  \n  .mobile-footer .fa-lg {\n    font-size: 1.5rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
