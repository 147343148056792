import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faChartLine, faCog, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { Row, Col, Nav, Form, Image, Navbar, Button, Dropdown, Container, ListGroup, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { getAllMessages } from '../../../src/api/private/messages'
import Profile3 from "../../assets/img/team/profile-picture-3.jpg";
import { Routes } from '../../routes'

export default (props) => {
  const [notifications, setNotifications] = useState([]);
  const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);
  const [signedIn, setIsSignedIn] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  const handleSignOut = () => {
    localStorage.removeItem('loggedin');
    localStorage.removeItem('FLC-JWT');
    localStorage.removeItem('token');
    setIsSignedIn(false);
  };

  useEffect(() => {
    const loggedIn = localStorage.getItem('loggedin');
    if (loggedIn === 'true') {
      setIsSignedIn(true);
      const user_details = JSON.parse(localStorage.getItem('user_details'));
      setUserDetails(user_details)
      console.log("This is the current user:", userDetails)
    } else {
      setIsSignedIn(false);
    }
    loadMessages();
  }, []);

  function loadMessages() {
    getAllMessages().then((_resp) => {
      if (_resp.data.Result === 'OK') {
        const records = _resp.data.Records;
        setNotifications(records);
      } else {
        console.warn(_resp.data);
      }
    }).catch((_resp) => {
      console.log(_resp);
    });
  }

  const markNotificationsAsRead = () => {
    setTimeout(() => {
      setNotifications(notifications.map(n => ({ ...n, read: true })));
    }, 300);
  };

  const Notification = (props) => {
    const { link, created_by, subject, received_time, body = "", read = false } = props;
    const readClassName = read ? "" : "text-danger";

    return (
      <ListGroup.Item action href={link} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col className="col-auto">
            <FontAwesomeIcon icon={faBell} />
          </Col>
          <Col className="ps-2 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-dark text-small">{created_by.email}</h4>
              </div>
            </div>
            <div className="text-start">
              <p className="font-small mt-1 mb-0"><strong>{subject}</strong></p>
              <p className="font-small mt-1 text-dark mb-0">{body.slice(0, 30)}...</p>
              <small className={readClassName}>{received_time.toLocaleString()}</small>
            </div>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <Form className="navbar-search">
              <Form.Group id="topbarSearch">
                <InputGroup className="input-group-merge search-bar">
                  <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                  <Form.Control type="text" placeholder="Search" />
                </InputGroup>
              </Form.Group>
            </Form>
          </div>
          <Nav className="align-items-center">
            <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead}>
              <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faBell} className="bell-shake" />
                  {areNotificationsRead ? null : <span className="icon-badge rounded-circle unread-notifications" />}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Dropdown.Item as="div" className="text-start text-primary fw-bold border-bottom border-light py-2">
                    Notifications
                  </Dropdown.Item>

                  {notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)}

                  <Dropdown.Item as={Link} to={Routes.Inbox.path} className="text-center text-primary fw-bold py-3">
                    View all
                  </Dropdown.Item>
                </ListGroup>
              </Dropdown.Menu>

            </Dropdown>

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <img src={Profile3} className="user-avatar md-avatar rounded-circle" />
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">{userDetails?.first_name} {userDetails?.last_name}</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> <Link to={Routes.Settings.path}>Profile Settings</Link>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faChartLine} className="me-2" /> <Link to={Routes.DashboardOverview.path}>Dashboard</Link>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" /> <Link to={Routes.Inbox.path}>Inbox</Link>
                </Dropdown.Item>
                <Dropdown.Item className="fw-bold">
                  <FontAwesomeIcon icon={faUserShield} className="me-2" /> <Link to={Routes.Contact.path}>Support</Link>
                </Dropdown.Item>

                <Dropdown.Divider />

                <Dropdown.Item className="fw-bold">
                  <Button variant="link" className="text-dark" onClick={handleSignOut}>
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Logout
                  </Button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
};